import React, { Fragment, memo, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core';

import {
  rootUrls,
  pluginsUrls,
  appearanceUrls,
  campaignUrls,
  pagesUrls,
  manageUrls,
  catalogueUrls,
  analyticsUrl,
  blogUrls,
  mediaLibraryUrls,
  accountUrls,
} from '../Urls';
import { useAppContext } from '../context/AppContext';
import { useOrderOptions } from '../context/OrderOptionsProvider';

import NavSpan from './NavSpan';
import {
  NavbarHomeIcon,
  NavbarHomeActiveIcon,
  NavbarOrdersIcon,
  NavbarOrdersActiveIcon,
  NavbarProductsIcon,
  NavbarProductsActiveIcon,
  NavbarPluginsIcon,
  NavbarPluginsActiveIcon,
  AppearanceActiveIcon,
  AppearanceInactiveIcon,
  NavbarCampaignIcon,
  NavbarCampaignActiveIcon,
  NavbarPaymentsIcon,
  NavbarPaymentsActiveIcon,
  NavbarDiscountsIcon,
  NavbarDiscountsActiveIcon,
  NavbarCustomersIcon,
  NavbarCustomersActiveIcon,
  WalletIcon,
  NavbarDeliveryIcon,
  NavbarDeliveryActiveIcon,
  NavbarAnalyticsActiveIcon,
  NavbarAnalyticsIcon,
  DownWhiteChevron,
  NavbarSettingsIcon,
  NavbarSettingsActiveIcon,
  ExternalLinkIcon,
  SwitchStoreIcon,
  DiamondIcon,
} from '../SvgIcon';

import { categoriesUrl, productsUrl } from '../UrlHelper';
import { useCredits } from '../context/CreditsProvider';
import { formatNumber, formatNumberToK, roundTo } from '../utils/number';
import { useCountry } from '../context/CountryProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import { useUnsavedChangesModal } from '../context/UnsavedChangesProvider';
import { ALL_STATUS } from '../Orders/constants';
import { usePlugins } from '../context/PluginsProvider';
import useModal from '../hooks/useModal';
import SelectStoreModal from '../Home/SelectStoreModal';
import { COMMISSION_WHITELIST_STORES } from '../constants';
import {
  DIGITAL_PRODUCTS_PLUGIN,
  PRODUCT_ATTRIBUTES_PLUGIN_ID,
} from '../Plugins/constants';
import { STAFF_ROLES } from '../Account/constants';
import { addCDN } from '../utils/file';
import { TrackEvent } from '../utils/analytics';
import { EVENT_OPEN_ANALYTICS_PAGE, EVENT_SECTION_NAVIGATED } from '../events';
import usePlanFeature from '../hooks/usePlanFeature';
import { useTheme } from '../context/ThemeProvider';
import defaultFavicon from '../../images/default-favicon.svg';
import { CASHFREE_KEY, usePayments } from '../context/PaymentsProvider';
import { LightTooltip } from '../shared/View/LightTooltip';
import { useSubscription } from '../context/SubscriptionProvider';

function generateMenuItem(
  label,
  path,
  icon,
  activeIcon,
  subSections,
  conditions
) {
  return {
    label,
    path,
    Icon: icon,
    ActiveIcon: activeIcon,
    subSections,
    conditions,
  };
}

const OrderPendingMenuBadge = memo(() => {
  const { newOrdersCount } = useOrderOptions();

  if (newOrdersCount < 1) {
    return null;
  }

  return <span className="menu-badge">{newOrdersCount}</span>;
});

OrderPendingMenuBadge.displayName = 'OrderPendingMenuBadge';

const MenuList = ({ location: { pathname: path } }) => {
  const { credits } = useCredits();
  const { isInternational } = useCountry();
  const { isFreePlan } = useSubscription();
  const { isUnsavedChanges, setShowLeaveSiteModal, setLinkRedirect } =
    useUnsavedChangesModal();
  const history = useHistory();
  const {
    business,
    isOwner,
    isAdmin,
    isManager,
    isVendor,
    canAvailFreeDukaanDelivery,
    sellerLimits,
    isStaff,
    stores,
    categoryCount,
    productCount,
    domainWithLink,
    roleName,
    allAbandonedOrdersCount,
  } = useAppContext();

  const { fetchActiveTheme, activeTheme } = useTheme();
  const { paymentProvider } = usePayments();

  const [favicon, setFavicon] = useState(defaultFavicon); // change default to seller web whitelabeld favicon

  let multipleStores = stores.length > 1;

  const { isBundleAllowed } = usePlanFeature();

  const multipleStoreFlow =
    (!isStaff && sellerLimits?.isMultiStoreFlow) || multipleStores;

  const { allOrdersCount, fetchStoreReturnPolicy } = useOrderOptions();
  const [allOrdersCountLocalStorage] = useLocalStorage('all_orders');
  const {
    pluginsStats,
    pluginCategory,
    isPluginInstalled,
    hasActivePluginsBeenFetched,
    fetchAllActivePlugins,
  } = usePlugins();

  const {
    isOpen: selectStoreModalOpen,
    openModal: openSelectStoreModal,
    closeModal: closeSelectStoreModal,
  } = useModal();

  const useStyles = makeStyles(() =>
    createStyles({
      tooltipPlacementRight: {
        marginLeft: '20px !important',
        padding: '4px 8px',
      },
    })
  );

  const menuItems = {
    homepage: generateMenuItem(
      'Dashboard',
      rootUrls.homePath,
      NavbarHomeIcon,
      NavbarHomeActiveIcon,
      undefined,
      [STAFF_ROLES.MANAGER]
    ),
    orders: generateMenuItem(
      'Orders',
      `${rootUrls.ordersPath}?status=${ALL_STATUS}`,
      NavbarOrdersIcon,
      NavbarOrdersActiveIcon,
      {
        allOrders: {
          label: 'All orders',
          path: `${rootUrls.ordersPath}?status=${ALL_STATUS}`,
          hideForStaffType: [STAFF_ROLES.MANAGER_L2],
        },
        ...(business?.order_returns_exist
          ? {
              returnItems: {
                label: 'Returns',
                path: `${rootUrls.returnOrdersPath}`,
              },
            }
          : {}),
        abandonedCarts: {
          label: 'Abandoned',
          path: rootUrls.abandonedOrdersPath,
          hideForStaffType: [STAFF_ROLES.MANAGER_L2],
        },
        commissionReport: {
          label: 'Commission report',
          path: rootUrls.commissionReportPath,
        },
      },
      [
        STAFF_ROLES.STAFF,
        STAFF_ROLES.STAFF_L1,
        STAFF_ROLES.STAFF_L2,
        STAFF_ROLES.STAFF_L3,
        STAFF_ROLES.MANAGER,
        STAFF_ROLES.MANAGER_L1,
        STAFF_ROLES.MANAGER_L2,
      ]
    ),
    delivery: generateMenuItem(
      isInternational ? 'Shipping' : 'Delivery',
      rootUrls.deliveryPath,
      NavbarDeliveryIcon,
      NavbarDeliveryActiveIcon,
      undefined,
      [STAFF_ROLES.STAFF, STAFF_ROLES.MANAGER, STAFF_ROLES.VENDOR]
    ),
    products: generateMenuItem(
      'Products',
      productsUrl,
      NavbarProductsIcon,
      NavbarProductsActiveIcon,
      {
        allProducts: {
          label: 'All products',
          path: productsUrl,
          hideForStaffType: [
            STAFF_ROLES.VENDOR,
            STAFF_ROLES.STAFF_L1,
            STAFF_ROLES.STAFF_L2,
            STAFF_ROLES.STAFF_L3,
            STAFF_ROLES.MANAGER_L1,
            STAFF_ROLES.MANAGER_L2,
          ],
        },
        ...(isBundleAllowed
          ? {
              bundles: {
                label: 'Bundles',
                path: catalogueUrls.bundlesPath,
                hideForStaffType: [STAFF_ROLES.VENDOR],
              },
            }
          : {}),
        categories: {
          label: 'Categories',
          path: categoriesUrl,
          hideForStaffType: [
            STAFF_ROLES.VENDOR,
            STAFF_ROLES.STAFF_L1,
            STAFF_ROLES.STAFF_L2,
            STAFF_ROLES.STAFF_L3,
            STAFF_ROLES.MANAGER_L1,
            STAFF_ROLES.MANAGER_L2,
          ],
        },
        inventory: {
          label: 'Inventory',
          path: catalogueUrls.productBulkPricesPath,
          hideForStaffType: [
            STAFF_ROLES.VENDOR,
            STAFF_ROLES.STAFF_L1,
            STAFF_ROLES.STAFF_L2,
            STAFF_ROLES.STAFF_L3,
            STAFF_ROLES.MANAGER_L1,
            STAFF_ROLES.MANAGER_L2,
          ],
        },
        ...(isPluginInstalled(PRODUCT_ATTRIBUTES_PLUGIN_ID)
          ? {
              attributes: {
                label: 'Attributes',
                path: catalogueUrls.attributesPath,
                hideForStaffType: [
                  STAFF_ROLES.VENDOR,
                  STAFF_ROLES.STAFF_L1,
                  STAFF_ROLES.STAFF_L2,
                  STAFF_ROLES.STAFF_L3,
                  STAFF_ROLES.MANAGER_L1,
                  STAFF_ROLES.MANAGER_L2,
                ],
              },
            }
          : {}),
      },
      [
        STAFF_ROLES.STAFF_L2,
        STAFF_ROLES.STAFF_L3,
        STAFF_ROLES.MANAGER,
        STAFF_ROLES.MANAGER_L1,
        STAFF_ROLES.VENDOR,
      ]
    ),
    // campaign: generateMenuItem(
    //   'Marketing',
    //   campaignUrls.home,
    //   NavbarCampaignIcon,
    //   NavbarCampaignActiveIcon,
    //   undefined,
    //   [
    //     'not_managerL1',
    //     'not_managerL2',
    //     'not_staff',
    //     'not_staffL1',
    //     'not_staffL2',
    //     'not_vendor',
    //   ]
    // ),
    analytics: generateMenuItem(
      'Analytics',
      analyticsUrl.businessOverviewPath,
      NavbarAnalyticsIcon,
      NavbarAnalyticsActiveIcon,
      {
        businessOverview: {
          label: 'Sales',
          path: analyticsUrl.businessOverviewPath,
        },
        storeOverview: {
          label: 'Traffic',
          path: analyticsUrl.storeOverviewPath,
        },
        productOverview: {
          label: 'Products',
          path: analyticsUrl.productOverviewPath,
        },
      },
      [STAFF_ROLES.MANAGER]
    ),
    payments: generateMenuItem(
      isInternational ? 'Payments' : 'Payouts',
      manageUrls.paymentsUrl,
      NavbarPaymentsIcon,
      NavbarPaymentsActiveIcon,
      undefined,
      [STAFF_ROLES.MANAGER_L2]
    ),
    coupons: generateMenuItem(
      'Discounts',
      manageUrls.couponsPath,
      NavbarDiscountsIcon,
      NavbarDiscountsActiveIcon,
      undefined,
      [STAFF_ROLES.MANAGER]
    ),
    customers: generateMenuItem(
      'Audience',
      manageUrls.customersPath,
      NavbarCustomersIcon,
      NavbarCustomersActiveIcon,
      undefined,
      [STAFF_ROLES.STAFF_L3, STAFF_ROLES.MANAGER, STAFF_ROLES.MANAGER_L1]
    ),
    appearance: generateMenuItem(
      'Appearance',
      appearanceUrls.themes,
      AppearanceInactiveIcon,
      AppearanceActiveIcon,
      {
        themes: {
          label: 'Themes',
          path: appearanceUrls.themes,
        },
        pages: {
          label: 'Pages',
          path: pagesUrls.pages,
        },
        menus: {
          label: 'Menus',
          path: appearanceUrls.menu,
        },
        blog: {
          label: 'Blog',
          path: blogUrls.blog,
        },
        media: {
          label: 'Media',
          path: mediaLibraryUrls.media,
        },
      },
      [STAFF_ROLES.MANAGER]
    ),
    plugins: generateMenuItem(
      'Plugins',
      pluginsUrls.pluginsPath,
      NavbarPluginsIcon,
      NavbarPluginsActiveIcon,
      {
        allPlugins: {
          label: 'All plugins',
          path: pluginsUrls.pluginsPath,
        },
        installedPlugins: {
          label: 'Installed',
          path: pluginsUrls.installedPluginsPath,
        },
      },
      [STAFF_ROLES.MANAGER]
    ),
    settings: generateMenuItem(
      'Settings',
      accountUrls.settingsPath,
      NavbarSettingsIcon,
      NavbarSettingsActiveIcon,
      undefined,
      [STAFF_ROLES.ADMIN]
    ),
  };

  const activeMenuItem = document.querySelector('[id^="scroll-to-menu"]');
  if (activeMenuItem) {
    activeMenuItem.scrollIntoView();
  }

  const handleHeaderClick = () => {
    if (isUnsavedChanges) {
      setShowLeaveSiteModal(true);
      setLinkRedirect(() => () => {
        if (multipleStoreFlow) {
          openSelectStoreModal();
          return;
        }
        history.push(rootUrls.homePath);
      });
    } else {
      if (multipleStoreFlow) {
        openSelectStoreModal();
        return;
      }
      history.push(rootUrls.homePath);
    }
  };

  if (isVendor) {
    menuItems.products.label = 'Inventory';
    menuItems.products.path = catalogueUrls.productBulkPricesPath;
  }
  useEffect(() => {
    if (!hasActivePluginsBeenFetched) fetchAllActivePlugins();
    fetchStoreReturnPolicy();
    fetchActiveTheme();
  }, []);

  useEffect(() => {
    if (activeTheme?.meta) {
      setFavicon(activeTheme?.meta?.favicon?.faviconUrl);
    }
  }, [activeTheme]);

  return (
    <div className="menu-list-container">
      <div
        className="menu-logo pos-rel cur-p"
        onClick={() => {
          window.open(domainWithLink, '_blank');
        }}
      >
        <div className="d-flex-c-s">
          <div className="d-flex align-center">
            <div>
              <div className="d-flex align-center store-header-item">
                <img
                  src={addCDN(favicon)}
                  style={{ height: 18, width: 18 }}
                  className="mr8"
                  alt="dukaan"
                />
                <p className="business-name mr4">{business?.name}</p>
              </div>
            </div>
          </div>
          <LightTooltip
            title={multipleStoreFlow ? 'Switch store' : 'View store'}
            placement="right"
          >
            {multipleStoreFlow ? (
              <div
                className="store-switch"
                onClick={(e) => {
                  e.stopPropagation();
                  handleHeaderClick();
                }}
              >
                <SwitchStoreIcon height={20} />
              </div>
            ) : (
              <div className="store-switch">
                <ExternalLinkIcon height={20} />
              </div>
            )}
          </LightTooltip>
        </div>
      </div>
      <div className="menu-list-wrap" id="menu-list-wrap-id">
        <ul>
          {Object.keys(menuItems).map((menuItemKey, menuItemIndex) => {
            const menuItem = menuItems[menuItemKey];
            const subSectionItems = menuItem.subSections;

            const { Icon, ActiveIcon } = menuItem;

            // Loops over all subSection paths & checks if current path matches it.
            const hasSubSectionsActive = Object.keys(subSectionItems ?? {})
              .map(
                (subSectionKey) =>
                  (path.includes(
                    (subSectionItems[subSectionKey].path || '').split('?')[0]
                  ) ||
                    (path.includes('/plugins') &&
                      subSectionItems[subSectionKey].path.includes(
                        '/plugins'
                      ))) &&
                  ![manageUrls.customersPath, rootUrls.managePath].some((str) =>
                    path.includes(str)
                  )
              )
              .includes(true);

            // checks if menu item exists if it does then it further checks fo condition
            const canRender =
              isAdmin || isOwner || menuItem?.conditions?.includes(roleName);

            if (!canRender) {
              return null;
            }
            if (
              menuItemKey === 'delivery' &&
              isPluginInstalled(DIGITAL_PRODUCTS_PLUGIN)
            ) {
              return null;
            }
            // if (
            //   menuItemKey === 'payments' &&
            //   paymentProvider !== CASHFREE_KEY
            // ) {
            //   return null;
            // }

            const isMenuItemActive =
              (path === '/' && menuItem.path === '/') ||
              (menuItem.path !== '/' && path.startsWith(menuItem.path)) ||
              (menuItem.path.includes('/plugins') &&
                path.startsWith('/plugins')) ||
              (menuItem.label === 'Orders' &&
                window.location.pathname.includes(rootUrls.createOrder)) ||
              hasSubSectionsActive;

            return (
              <Fragment key={menuItemKey}>
                <li
                  id={
                    isMenuItemActive && menuItemIndex > 8
                      ? `scroll-to-menu-${menuItemKey}`
                      : `menu-${menuItemKey}`
                  }
                  onClick={() => {
                    TrackEvent(EVENT_SECTION_NAVIGATED, business, {
                      Section_Name: menuItemKey,
                    });

                    if (menuItemKey === 'analytics')
                      TrackEvent(EVENT_OPEN_ANALYTICS_PAGE, business);
                  }}
                >
                  <NavSpan
                    to={menuItem.path}
                    path={path}
                    isActive={() => isMenuItemActive}
                  >
                    <Icon className="nav-inactive-icon" />
                    <ActiveIcon className="nav-active-icon" />
                    <div className="title text-8">{menuItem.label}</div>
                    {/* Modifiers */}
                    {menuItemKey === 'orders' && <OrderPendingMenuBadge />}
                    {menuItemKey === 'delivery' &&
                      canAvailFreeDukaanDelivery && (
                        <span className="free-badge">FREE</span>
                      )}
                  </NavSpan>
                </li>
                {hasSubSectionsActive &&
                  Object.keys(subSectionItems).map((subSectionKey) => {
                    const subSection = subSectionItems[subSectionKey];
                    const {
                      meta: { feature_whitelist: featureWhitelist = {} } = {},
                    } = business;
                    const isFeaturesEnabled = Boolean(
                      subSection?.showOnlyOn?.some(
                        (each) => featureWhitelist?.[each]
                      )
                    );
                    if (
                      (subSection.hideForInternational && isInternational) ||
                      (subSection?.showOnlyOn?.length && !isFeaturesEnabled) ||
                      (subSection?.hideForStaffType?.length > 0 &&
                        subSection?.hideForStaffType?.includes(roleName)) ||
                      (!COMMISSION_WHITELIST_STORES.includes(business?.id) &&
                        subSection?.path.includes(
                          rootUrls.commissionReportPath
                        ))
                    )
                      return null;
                    const subSectionPath = subSection.path;
                    const isActive =
                      path.includes((subSectionPath || '').split('?')[0]) ||
                      (path.includes('/plugins') &&
                        subSectionKey === pluginCategory);

                    return (
                      <div
                        key={subSectionKey}
                        className={cx(
                          'pl56 mb14 cur-p text-4 pos-rel',
                          { 'c-white text-medium': isActive },
                          { 'c-gray-6': !isActive }
                        )}
                        onClick={(e) => {
                          if (isUnsavedChanges) {
                            e.preventDefault();
                            setShowLeaveSiteModal(true);
                            setLinkRedirect(subSectionPath);
                          } else {
                            history.replace(subSectionPath);
                          }
                        }}
                      >
                        {subSection.label}

                        {/* Modifiers */}
                        {subSectionKey === 'allProducts' &&
                          productCount > 0 && (
                            <span className="menu-badge transparent">
                              {productCount}
                            </span>
                          )}
                        {subSectionKey === 'categories' &&
                          categoryCount > 0 && (
                            <span className="menu-badge transparent">
                              {categoryCount}
                            </span>
                          )}
                        {subSectionKey === 'allOrders' &&
                          (!!allOrdersCount ||
                            !!allOrdersCountLocalStorage) && (
                            <span className="menu-badge transparent">
                              {`${formatNumberToK(
                                allOrdersCount || allOrdersCountLocalStorage
                              )}`}
                            </span>
                          )}
                        {subSectionKey === 'abandonedCarts' &&
                          !!allAbandonedOrdersCount && (
                            <span className="menu-badge transparent">
                              {`${formatNumberToK(allAbandonedOrdersCount)}`}
                            </span>
                          )}
                        {subSectionKey === 'allPlugins' &&
                          !!pluginsStats?.all && (
                            <span className="menu-badge transparent">
                              {` ${pluginsStats?.all}`}
                            </span>
                          )}
                        {subSectionKey === 'installedPlugins' &&
                          !!pluginsStats?.installed && (
                            <span className="menu-badge transparent">
                              {`${pluginsStats?.installed}`}
                            </span>
                          )}
                      </div>
                    );
                  })}
              </Fragment>
            );
          })}
        </ul>
      </div>
      <div className="menu-list-sub-item">
        {isFreePlan && (
          <div
            className="upgrade-plan mb8"
            onClick={(e) => {
              history.push(accountUrls.subscriptionPurchasePath);
            }}
          >
            <div className="d-flex-c-start">
              <DiamondIcon className="mr20" />
              <div>
                <p className="text-4 text-white">Upgrade plan</p>
                <p className="c-gray-1 text-6">Get extra benefits</p>
              </div>
            </div>
          </div>
        )}
        {!isInternational && (isOwner || isAdmin || isManager) && (
          <div
            className="dukaan-credits"
            onClick={(e) => {
              if (isUnsavedChanges) {
                e.preventDefault();
                setShowLeaveSiteModal(true);
                setLinkRedirect(campaignUrls.credits);
              } else {
                history.push(campaignUrls.credits);
              }
            }}
          >
            <div className="d-flex-c-start">
              <WalletIcon className="mr12" />
              <div>
                <div className="text-4 text-white available-credits-text">
                  Dukaan Credits
                </div>
                <div className="section-text-5 mt2">
                  {formatNumber(roundTo(credits || 0))}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {isInternational && (
          <a
            className="bottom-section"
            href={SUBMIT_A_REQUEST_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <div className="text-4 text-white available-credits-text mb2">
              Any feature suggestions?
            </div>
            <div className="section-text-5 text-white">Submit a request</div>
          </a>
        )} */}
      </div>
      <SelectStoreModal
        open={selectStoreModalOpen}
        closeModal={closeSelectStoreModal}
      />
    </div>
  );
};

export default withRouter(MenuList);
