import { WEBVIEW_INJECTED_JAVASCRIPT_OBJECT } from '../webview';

export const getInjectedJavascriptValueFromWebView = (key) => {
  try {
    if (
      window?.ReactNativeWebView?.injectedObjectJson() &&
      JSON.parse(window.ReactNativeWebView.injectedObjectJson())?.[
        WEBVIEW_INJECTED_JAVASCRIPT_OBJECT.isRNWebView
      ]
    ) {
      return JSON.parse(window.ReactNativeWebView.injectedObjectJson())?.[key];
    }

    return null;
  } catch (error) {
    console.error(
      'An error occurred while getting injected javascript object from webview:',
      error
    );
  }
};

export const postMessageToWebView = (message) => {
  try {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  } catch (error) {
    console.error('An error occurred while posting message to webview:', error);
  }
};
