import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useCountry } from '../../context/CountryProvider';
import SpinnerButton from '../SpinnerButton';
import { HELLOBAR_CONFIG } from './constants';

const useStyles = (props) => makeStyles({ ...props } ?? {});

const HelloBar = ({ isMobile = false }) => {
  const {
    setIsHelloBarActive,
    festiveDiscountPercentage: discountPercent,
    isEnterprise,
  } = useAppContext();

  const history = useHistory();
  const { isInternational } = useCountry();

  const [activeConfig, setActiveConfig] = useState(null);
  const [shouldConfigUpdate, setShouldConfigUpdate] = useState(true);
  const [keepTimerDiv, setKeepTimerDiv] = useState(false);
  const styles = useStyles(activeConfig?.classes ?? {})();

  useEffect(() => {
    let interval = null;

    const config = HELLOBAR_CONFIG({
      isMobile,
      isInternational,
      discountPercent,
    });
    const currentConfig = config.find((item) =>
      isInternational
        ? new Date().getTime() -
            new Date(item.startDateInternational).getTime() >
            0 &&
          new Date(item.endDateInternational).getTime() - new Date().getTime() >
            0
        : new Date().getTime() - new Date(item.startDate).getTime() > 0 &&
          new Date(item.endDate).getTime() - new Date().getTime() > 0
    );
    setActiveConfig(currentConfig);

    if (currentConfig) {
      const countDownDate = new Date(
        isInternational
          ? currentConfig.timerEndDateInternational
          : currentConfig.timerEndDate
      ).getTime();

      let now = new Date().getTime();
      if (currentConfig?.showTimer && countDownDate - now > 0) {
        setIsHelloBarActive(countDownDate - now > 0);
        setKeepTimerDiv(true);

        // Update the count down every 1 second
        interval = setInterval(function () {
          // Get today's date and time
          now = new Date().getTime();
          // Find the distance between now and the count down date
          const distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          const day = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hour = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minute = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          const second = Math.floor((distance % (1000 * 60)) / 1000);

          // always show 0 as days left
          document.getElementById('days').innerHTML = `0${
            day <= 0 ? 0 : day
          }`.slice(-2);
          document.getElementById('hours').innerHTML = `0${
            hour <= 0 ? 0 : hour
          }`.slice(-2);
          document.getElementById('minutes').innerHTML = `0${
            minute <= 0 ? 0 : minute
          }`.slice(-2);
          document.getElementById('seconds').innerHTML = `0${
            second <= 0 ? 0 : second
          }`.slice(-2);

          if (day <= 0 && hour <= 0 && minute <= 0 && second <= 0) {
            setShouldConfigUpdate((prev) => !prev);
            setIsHelloBarActive(false);
            setKeepTimerDiv(currentConfig.showDealAfterTimerEnd);
          }
        }, 1000);
      } else if (!isEnterprise) {
        setIsHelloBarActive(true);
      }
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isInternational, shouldConfigUpdate]);

  return activeConfig ? (
    <div
      className={cx('d-flex align-center', styles?.root ?? '', {
        'flex-column': isMobile,
        'justify-center': isMobile || (!isMobile && isInternational),
        'space-between': !isMobile && !isInternational,
      })}
      style={{ height: isMobile ? '' : 65 }}
    >
      <div className={cx('d-flex-c-c', styles?.gradientDiv ?? '')}>
        {activeConfig?.showIconOnLeft && (
          <activeConfig.icon
            height={16}
            width={16}
            className={cx('mr8', styles?.icon)}
          />
        )}
        <p className={styles?.boldText ?? ''}>{activeConfig.dealName}</p>
        {activeConfig?.showIconOnRight && (
          <activeConfig.icon
            height={16}
            width={16}
            className={cx('ml8', styles?.icon)}
          />
        )}
      </div>

      <div
        className={cx('d-flex-c-c', styles?.discountTextDiv ?? '', {
          mt12: isMobile,
          ml48: !isMobile && isInternational,
        })}
      >
        <p
          className={cx('text-4', styles?.discountText ?? '', {
            'text-center': isMobile,
          })}
        >
          {isInternational
            ? activeConfig.discountTextInternational
            : activeConfig.discountText}
        </p>

        {activeConfig.showButtonRedirect && (
          <SpinnerButton
            className={cx('ml16 btn section-text-7', styles?.button ?? '')}
            onClick={() => {
              history.push(activeConfig.buttonRedirectLink);
            }}
            showAnimation
          >
            {React.createElement(activeConfig.buttonText)}
          </SpinnerButton>
        )}
      </div>

      {keepTimerDiv && activeConfig.showTimer && (
        <div className={cx('c-white', styles?.timer, { mt6: isMobile })}>
          <div className="d-flex">
            <div
              className={cx('d-flex justify-center flex-column', {
                'align-start mr12': !isMobile,
                'align-center mx4': isMobile,
              })}
            >
              <p className="section-text-5">
                <span id="days">00</span> {!isMobile && <span>:</span>}
              </p>
              <p className="text-10">Days</p>
            </div>
            {isMobile && <span className="section-text-5">:</span>}
            <div
              className={cx('d-flex justify-center flex-column', {
                'align-start mr12': !isMobile,
                'align-center mx4': isMobile,
              })}
            >
              <p className="section-text-5">
                <span id="hours">00</span> {!isMobile && <span>:</span>}
              </p>
              <p className="text-10">Hours</p>
            </div>
            {isMobile && <span className="section-text-5">:</span>}
            <div
              className={cx('d-flex justify-center flex-column', {
                'align-start mr12': !isMobile,
                'align-center mx4': isMobile,
              })}
            >
              <p className="section-text-5">
                <span id="minutes">00</span> {!isMobile && <span>:</span>}
              </p>
              <p className="text-10">Minutes</p>
            </div>
            {isMobile && <span className="section-text-5">:</span>}
            <div
              className={cx('d-flex justify-center flex-column', {
                'align-start': !isMobile,
                'align-center': isMobile,
              })}
            >
              <p className="section-text-5">
                <span id="seconds">00</span>
              </p>
              <p className="text-10">Seconds</p>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default HelloBar;
