import { EggIcon, NonVegIcon, OthersIcon, VegIcon } from '../SvgIcon';
import {
  D2C_APPEARENCE_THEME,
  LEO,
  LIGHTSPEED_APPEARENCE_THEME,
  MATRIX_THEME,
  MANA_THEME,
  ENIGMA,
  URSA,
  LUXURY,
  TINKER,
  BRACER,
  CATALYST,
  DAVID,
  LINKEN,
  METEORA,
  PALADIN,
  SAGE,
  YASHA,
  ZENITH,
} from '../Themes/constants';

export const HTML_TAGS_REGEX = /<(\S*?)[^>]*>|<\\(\S*?)>|&nbsp;/g;
export const PRODUCT_DESC_MAX_COUNT = 50000;
export const BUNDLE_DESC_MAX_COUNT = 4000;
export const VARIANT_SIZE = 1;
export const VARIANT_SELLING_PRICE = 3;
export const VARIANT_ORIGINAL_PRICE = 4;
export const MULTILINE_ATTRIBUTE_TEXT_MAX_COUNT = 4000;
export const VARIANT_COLOR_TYPE = 'color picker';
export const VARIANT_SIZE_TYPE = 'size';

export const MAX_ALLOWED_PRICE = 999999999999999; // 15 digits

// when selecting category for some use like assigning to product
export const MULTI_LEVEL_CATEGORY_MAX_DEPTH_ADD_NEW_CATEGORY = 2;
// when selecting parent category for sub category
export const MULTI_LEVEL_CATEGORY_MAX_DEPTH_SELECT_CATEGORY = 1;

export const DIGITAL_PRODUCTS_MAX_FILESIZE_LIMIT = 200000000; // 200 MB
export const MAX_VARIANT_TYPE_ALLOWED = 2;

export const DEFAULT_NAVIGATION_ITEMS = (showAdditionalInformation = false) => {
  const items = [
    { label: 'Product Information', id: 'product-information' },
    { label: 'Product Media', id: 'product-images' },
    { label: 'Inventory', id: 'product-inventory' },
  ];

  items.push({ label: 'Variants', id: 'variants' });
  if (showAdditionalInformation)
    items.push({ label: 'Additional Information', id: 'additional-info' });

  return items;
};

export const onDigitalProductsInstalledRemoveList = [
  'product-inventory',
  'variants',
  'shipping',
];

export const COUNTDOWN_TIMER_ID = '60682faba1fbf915bed2e5b5';
export const ADV_COUNTDOWN_TIMER_APP_ID = '62e2281b34db4f8462f4fc36';
export const DUKAAN_SEO_ID = '60b5de77e3320a1125460a88';
export const FAQ_PLUGIN_ID = '634401973d4fbe76d450e5a4';

export const CATEGORY_TYPES = {
  MAIN: 'main',
  SUBCATEGORY: 'subcategory',
};

export const CATALOG_BUILDER_CATEGORIES = [1, 5, 6, 10];

export const SUBCATEGORIES_LIMIT = 12;

export const QUANTITY_UNITS = [
  'piece',
  'kg',
  'gm',
  'ml',
  'litre',
  'mm',
  'ft',
  'meter',
  'sq. ft.',
  'sq. meter',
  'km',
  'set',
  'hour',
  'day',
  'bunch',
  'bundle',
  'month',
  'year',
  'service',
  'work',
  'packet',
  'box',
  'pound',
  'dozen',
  'gunta',
  'pair',
  'minute',
  'quintal',
  'ton',
  'capsule',
  'tablet',
  'plate',
  'inch',
  'ounce',
  'bottle',
  'night',
  'tour',
];

export const DEFAULT_UNIT = 'piece';

export const QUANTITY_UNITS_OPTIONS = QUANTITY_UNITS.map((value) => ({
  label: value,
  value,
}));

export const PRODUCT_WEIGHTS = ['kg', 'gm'];

export const PRODUCT_WEIGHTS_OPTIONS = PRODUCT_WEIGHTS.map((value) => ({
  label: value,
  value,
}));

export const PRODUCT_DIMENSION = ['cm', 'in'];

export const PRODUCT_DIMENSION_OPTIONS = PRODUCT_DIMENSION.map((value) => ({
  label: value,
  value,
}));

export const VARIANT_OPTIONS = [
  {
    label: 'Size',
    value: 1,
  },
  {
    label: 'Colour',
    value: 2,
  },
];

export const ADD_CATEGORY_OPTION = {
  label: '+ Add new category',
  value: -1,
};

export const FOOD_CATEGORY_OPTIONS = [
  { value: 1, label: 'Veg', icon: VegIcon },
  { value: 2, label: 'Non Veg', icon: NonVegIcon },
  { value: 3, label: 'Egg', icon: EggIcon },
  { value: 4, label: 'Others', icon: OthersIcon },
];

export const DINING_CUSTOMER = 1;
export const NON_DINING_CUSTOMER = 2;
export const BOTH_DINING_AND_NON_DINING_CUSTOMER = 3;

export const CATEGORY_APPLICABLE_TO = [
  {
    value: BOTH_DINING_AND_NON_DINING_CUSTOMER,
    label: 'Both delivery & dining customers',
  },
  { value: NON_DINING_CUSTOMER, label: 'Delivery customers only' },
  { value: DINING_CUSTOMER, label: 'Dining customers only' },
];

export const CATALOG_CATEGORIES_MAP = {
  5: 'Fruits & Vegetables',
  1: 'Grocery & Essentials',
  6: 'Restaurants & Fast Food',
  10: 'Chicken, Meat & Fish',
};

export const imageRecommendedSize = {
  [LEO]: '(1000px*1000px)',
  [MATRIX_THEME]: '(1000px*1248px)',
  [LIGHTSPEED_APPEARENCE_THEME]: '(1000px*1000px)',
  [LUXURY]: '(1000px*1248px)',
  [D2C_APPEARENCE_THEME]: '(1000px*1248px)',
  [MANA_THEME]: '(1000px*1248px)',
  [ENIGMA]: '(1000px*1000px)',
  [URSA]: '(1000px*1248px)',
  [TINKER]: '(1000px*1000px)',
  [BRACER]: '(1000px*1515px)',
  [CATALYST]: '(1000px*1000px)',
  [DAVID]: '(1000px*1000px)',
  [LINKEN]: '(1000px*1000px)',
  [METEORA]: '(1000px*1000px)',
  [PALADIN]: '(1000px*1000px)',
  [SAGE]: '(1000px*1000px)',
  [YASHA]: '(1000px*1000px)',
  [ZENITH]: '(1000px*1000px)',
};

export const HSN_EXTERNAL_LINK =
  'https://services.gst.gov.in/services/searchhsnsac';

export const DEFAULT_CATEGORY_NAVIGATION_ITEMS = (
  isSeoInstalled,
  isFaqInstalled,
  subcategory = false,
  showProducts = false,
  showSubcategory = false,
  showAttributes = false
) => {
  const items = [
    {
      label: `Information`,
      id: 'category-information',
    },
    {
      label: `Banner`,
      id: 'category-banner',
    },
    {
      label: `Content`,
      id: 'category-content',
    },
    ...(showSubcategory
      ? [
          {
            label: `Subcategories`,
            id: 'product-inventory',
          },
        ]
      : []),
    ...(showProducts
      ? [
          {
            label: `Products`,
            id: 'category-products',
          },
        ]
      : []),
  ];
  if (showAttributes) {
    items.push({ label: 'Attributes', id: 'attributes' });
  }

  if (isSeoInstalled) {
    items.push({ label: 'Dukaan SEO', id: 'dukaan-seo' });
  }
  if (isFaqInstalled) {
    items.push({ label: 'FAQs', id: 'faqs' });
  }

  return items;
};

export const MAX_IMAGES_MAIN_PRODUCT_RESTAURANT = 1;
export const MAX_IMAGES_MAIN_PRODUCT_OTHERS = 50;

export const cleanSkuImageData = ({ data }) => {
  const hasError = false;

  data?.skus?.map((variant, index) => {
    // delete variant "primary_image" key if null
    if (
      variant.primary_image === null ||
      variant.primary_image === undefined ||
      variant.primary_image === ''
    ) {
      delete data.skus[index].primary_image;
    }
    return true;
  });

  return { hasError, data };
};

export const SELLING_PRICE_NODE = 3;
export const ORIGINAL_PRICE_NODE = 4;

export const PAGE_SIZE_FOR_BRAND_AND_TAG = 30;
export const PRODUCTS_PAGE_SIZE = 30;

export const FILTER_OPTIONS = {
  STOCK_STATUSES: 'stock_statuses',
  TAGS: 'tags',
  BRAND: 'brand',
  VENDOR: 'vendors',
};

export const FILTER_STOCK_STATUS_OPTIONS = {
  IN_STOCK: 'in_stock',
  OUT_OF_STOCK: 'out_of_stock',
  LOW_STOCK: 'low_stock',
  HIDDEN: 'hidden',
  IS_ACTIVE: 'is_active',
};

export const INITIAL_FILTER = {
  stock_statuses: [],
  tags: [],
  brand: [],
};

export const FILTER_LIST_SIZE = 20;

export const PRODUCTS_SORT_OPTIONS = {
  CREATED_AT_DESC: 'created_at-desc',
  CREATED_AT_ASC: 'created_at-asc',
  MODIFIED_AT_DESC: 'modified_at-desc',
  MODIFIED_AT_ASC: 'modified_at-asc',
  NAME_ASC: 'name-asc',
  NAME_DESC: 'name-desc',
  INVENTORY_QUANTITY_DESC: 'inventory_quantity-desc',
  INVENTORY_QUANTITY_ASC: 'inventory_quantity-asc',
  SELLING_PRICE_DESC: 'selling_price-desc',
  SELLING_PRICE_ASC: 'selling_price-asc',
};

export const CATEGORIES_SORT_OPTIONS = {
  CREATED_AT_DESC: 'created_at-desc',
  CREATED_AT_ASC: 'created_at-asc',
  MODIFIED_AT_DESC: 'modified_at-desc',
  MODIFIED_AT_ASC: 'modified_at-asc',
  NAME_ASC: 'name-asc',
  NAME_DESC: 'name-desc',
  PRODUCT_QUANTITY_DESC: 'product_count-desc',
  PRODUCT_QUANTITY_ASC: 'product_count-asc',
};

export const PRODUCTS_BULK_ACTIONS = {
  ACTIVE: 'active',
  HIDDEN: 'hidden',
  DELETE: 'delete',
  ADD_TO_CATEGORY: 'add_to_category',
  MOVE_TO_CATEGORY: 'move_to_category',
  REMOVE_FROM_CATEGORY: 'remove_products_from_category',
};

export const PAGINATION_BEHAVIOUR = {
  INFINITE: 'infinite',
  SPLIT: 'split',
};

export const COMPONENT_TYPE = {
  PRODUCT: 'PRODUCT',
  CATEGORY: 'CATEGORY',
  SUBCATEGORY: 'SUBCATEGORY',
  BLOG: 'BLOG',
  PAGE: 'PAGE',
  BUNDLE: 'BUNDLE',
};

export const SEO_ERROR_MESSAGE = {
  PRODUCT: 'product name',
  CATEGORY: 'category name',
  SUBCATEGORY: 'subcategory name',
  BLOG: 'blog title',
  PAGE: 'page title',
  BUNDLE: 'bundle name',
};

export const CATAGORIES_BULK_ACTIONS = {
  ACTIVE: 'active',
  HIDDEN: 'hidden',
  DELETE: 'delete',
};

export const CATEGORY_FILTER_KEYS = ['in_stock', 'is_active'];

export const MAX_SLUG_LENGTH = 300;

export const PRODUCT_TYPES = {
  NORMAL: 1,
  BUNDLES: 2,
};

export const GET_GOOGLE_PRODUCT_CATEGORY =
  'https://www.google.com/basepages/producttype/taxonomy-with-ids.en-US.txt';

export const CATALOGUE_TYPE = {
  PRODUCT_INFORMATION: 'product_information',
  INVENTORY: 'inventory',
  ATTRIBUTES: 'attributes',
};
