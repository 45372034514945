import moment from 'moment';
import React, { Component } from 'react';
// import * as amplitude from '@amplitude/analytics-browser';
import mixpanel from 'mixpanel-browser';
import AppContext from './AppContext';
import initialState from './initialState';
import * as User from '../utils/user';
import {
  setDataToLocalStorage,
  getDataFromLocalStorage,
  removeItem,
  setItem,
  getItem,
} from '../utils/storage';
import { getRequest, postRequest } from '../utils/http';
import {
  STORE_DETAIL_URL,
  REFRESH_TOKEN_URL,
  STORE_SERVICE_CHARGE_URL,
  STORE_CATEGORIES_URL,
  CATEGORIES_URL,
  CONSTANTS_URL,
  ANNOUNCEMENTS_URL,
  STORE_TOKEN_URL,
  GET_SELLER_PLAN,
  UPDATE_NUMBER_URL,
  STORE_LIST_URL,
  STORE_CATELOGUE_COUNT_URL,
  STORE_ADDITIONAL_INFO_URL,
  ABANDONED_ORDER_COUNT_STATS,
} from '../ApiUrls';
import { DURATION_FILTER_OPTIONS } from '../Home/constants';
import {
  accountUrls,
  authUrls,
  externalLoginPaths,
  internalUrl,
  rootUrls,
} from '../Urls';
import { convertObjKeyFromSnakeToCamelCase, noop, uniqueByKey } from '../utils';
import {
  APP_DOMAIN,
  ENVIRONMENTS,
  MIXPANEL_TOKEN,
  SELECTED_ENVIRONMENT_KEY,
  SELLER_WEB_DOMAIN,
  STAGING_SELLER_LINK,
} from '../constants';
import { RESELLER_ACCOUNT_TYPE, STAFF_ROLES } from '../Account/constants';
import { parseJwt } from '../utils/string';
import { NON_INTERNATIONAL_COUNTRY_CODE } from './CountryProvider';
import { SMS_MODE } from '../InternationalLogin/Mobile/constants';
import { getInjectedJavascriptValueFromWebView } from '../utils/webview';
import { WEBVIEW_INJECTED_JAVASCRIPT_OBJECT } from '../webview';

const STORAGE_KEY = 'v2__busi__detail';

const TABLE_STORAGE_KEY = '__tab_detail__';
const FREE_DELIVERY_AVAIL_KEY = 'can_avail';
export const SELECTED_STORE_ID_KEY = 'sl_store_id';

class AppProvider extends Component {
  constructor(props) {
    super(props);

    // check if user is on non secure origin and redirect to secure version
    if (
      window.location.hostname !== 'localhost' &&
      window.location.port === 'http:'
    ) {
      window.location.href = SELLER_WEB_DOMAIN;
    }

    const authToken = User.getAuthToken();
    let state;
    if (authToken) {
      const storeId = getInjectedJavascriptValueFromWebView(
        WEBVIEW_INJECTED_JAVASCRIPT_OBJECT.storeId
      );
      const staffRole = getInjectedJavascriptValueFromWebView(
        WEBVIEW_INJECTED_JAVASCRIPT_OBJECT.staffRole
      );
      const meta = getInjectedJavascriptValueFromWebView(
        WEBVIEW_INJECTED_JAVASCRIPT_OBJECT.meta
      );

      const business = getDataFromLocalStorage(STORAGE_KEY, {});
      if (storeId && staffRole && meta) {
        business.id = storeId;
        business.role_name = staffRole;
        business.meta = meta;
      }

      const storeIds = parseJwt(authToken).store_ids;
      localStorage.setItem(SELECTED_STORE_ID_KEY, storeIds[0]);

      const canAvailFreeDukaanDelivery =
        business?.meta?.misc_data?.free_delivery === FREE_DELIVERY_AVAIL_KEY &&
        business.role_name !== STAFF_ROLES.VENDOR;
      const isPharma = (business.categories || []).includes(7);
      const orderCreationSources =
        business?.meta?.misc_data?.order_sources || [];

      let isEnterprise = false;
      const host = window.location.host;

      let currentEnv = null;
      if (getItem(SELECTED_ENVIRONMENT_KEY)) {
        const { value } = JSON.parse(getItem(SELECTED_ENVIRONMENT_KEY));
        currentEnv = value;
      }
      if (
        !(
          host.startsWith('admin.') ||
          host.startsWith('web.') ||
          host.includes('localhost') ||
          host.includes(STAGING_SELLER_LINK) ||
          host.includes('vercel.app')
        ) ||
        currentEnv === ENVIRONMENTS.ENTERPRISE_ENV
      ) {
        isEnterprise = true;
      }

      state = {
        ...initialState,
        statsDuration: DURATION_FILTER_OPTIONS[4].value,
        authToken,
        business,
        constants: {},
        canAvailFreeDukaanDelivery,
        isPharma,
        categories: [],
        intlCategories: [],
        allProductCategories: [],
        currentCategoryData: {},
        refreshToken: User.getRefreshToken(),
        userDetails: User.getUserDetails(),
        domain: null,
        displayDomain: null,
        announcements: [],
        announcementsLoading: false,
        newAnnouncements: 0,
        stores: [],
        sellerLimits: {},
        total_lifetime_orders: null,
        loading: false,
        storesListPageNumber: 1,
        hasMoreStores: '',
        storesListLoading: false,
        orderCreationSources,
        isEnterprise,
      };
    } else {
      state = {
        ...initialState,
        statsDuration: DURATION_FILTER_OPTIONS[4].value,
      };
    }
    this.state = state;
  }

  componentDidMount() {
    const { authToken } = this.state;
    if (
      authToken &&
      authUrls.registerPath !== window.location.pathname &&
      !externalLoginPaths.includes(window.location.pathname)
    ) {
      this.fetchStores();
      this.fetchBusinessDetails();
      this.fetchStoreCategories();
      this.fetchConstants();
      this.fetchAnnouncements();
      this.fetchSellerPlanStats();
      // this.fetchBusinessStats();
    }
    mixpanel.init(MIXPANEL_TOKEN, {
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  componentDidUpdate(nextProps, nextState) {
    const { authToken, storesListLoading, stores } = this.state;
    if (
      authUrls.selectStorePath !== window.location.pathname &&
      nextState.authToken &&
      authToken &&
      ![...externalLoginPaths, authUrls.registerPath].includes(
        window.location.pathname
      ) &&
      (authToken !== nextState.authToken ||
        (stores.length <= 0 && !storesListLoading))
    ) {
      const storeIds = parseJwt(nextState.authToken)?.store_ids;
      if (storeIds?.length) {
        this.fetchStores();
        this.fetchStoreCategories();
        this.fetchConstants();
        this.fetchAnnouncements();
        this.fetchSellerPlanStats();
      }
    }
  }

  setUser = (user, userDetails, callbackFn = noop) => {
    const username = parseJwt(user.access_token)?.username;
    // username && amplitude.setUserId(username);
    username && mixpanel.identify(username);
    this.setToken(user.access_token);
    this.setRToken(user.refresh_token);
    this.setUserDetails(userDetails);
    this.fetchBusinessDetails(callbackFn, true, true);
  };

  setToken = (authToken) => {
    User.setAuthToken(authToken);
    this.setState({ authToken });
  };

  setUserDetails = (userDetails) => {
    User.setUserDetails(userDetails);
    this.setState({ userDetails });
  };

  setStoreId = (storeId) => {
    setItem(SELECTED_STORE_ID_KEY, storeId);
  };

  setRToken = (refreshToken) => {
    User.setRefreshToken(refreshToken);
    this.setState({ refreshToken });
  };

  setBusiness = (business) => {
    this.setState({
      business,
    });
    setDataToLocalStorage(STORAGE_KEY, business);
  };

  setStoreServiceCharges = (storeServiceCharges) => {
    this.setState({ storeServiceCharges });
  };

  setStoreTable = (table) => {
    setDataToLocalStorage(TABLE_STORAGE_KEY, table);
  };

  setLifetimeOrderCount = (total) => {
    this.setState({ total_lifetime_orders: total });
    const { clarity } = window;
    if (typeof clarity === 'function') {
      clarity('set', 'order_count', total);
    }
  };

  fetchStoreTable = () => getDataFromLocalStorage(TABLE_STORAGE_KEY);

  removeStoreTable = () => removeItem(TABLE_STORAGE_KEY);

  fetchRefreshToken = () => {
    const { authToken, refreshToken } = this.state;
    if (authToken) {
      postRequest({
        url: REFRESH_TOKEN_URL,
        data: {
          refresh: refreshToken,
        },
      })
        .then((res) => {
          if (res && res.access) {
            this.setToken(res.access);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  fetchSellerPlanStats = (cb = noop) => {
    getRequest({
      url: GET_SELLER_PLAN,
    }).then((res) => {
      const PlanData = {
        ...res.data,
        isMultiStoreFlow: res.data.max_limit > 1,
        isUnlimitedPlan: res.data.max_limit > 1000,
      };
      this.setState({
        sellerLimits: {
          ...PlanData,
        },
      });
      cb({
        ...PlanData,
      });
    });
  };

  // for finding store obj which is presented in pagination page > 1
  getApiCall = (page = 1) =>
    getRequest({
      url: STORE_DETAIL_URL,
      data: {
        page,
      },
    });

  findStoreObj = async (initialData, selectedStore, page = 2) => {
    let allData = [...initialData];
    let selectedStoreObj = '';

    while (!selectedStoreObj) {
      const data = this.getApiCall(page);
      await data.then(({ results }) => (allData = [...allData, ...results]));
      selectedStoreObj = allData.find(
        (storeItem) => storeItem.id === Number(selectedStore)
      );
      page++;
    }

    return selectedStoreObj;
  };

  fetchStores = () => {
    this.setState({ storesListLoading: true });
    const STORE_LIST_PAGE_SIZE = 30;
    getRequest({
      url: STORE_LIST_URL,
      data: {
        offset: (this.state.storesListPageNumber - 1) * 30,
      },
    })
      .then(({ results }) => {
        if (results.length === 0) throw new Error('No store found.');
        const sellerStores = results.map((storeItem) => ({
          name: storeItem.name,
          id: storeItem.id,
          link: storeItem?.custom_domain ?? `mydukaan.io/${storeItem.link}`,
          uuid: storeItem.uuid,
          seller: storeItem.seller_id,
        }));
        this.setState((pre) => ({
          stores: uniqueByKey(
            [
              ...(pre.storesListPageNumber === 1 ? [] : pre.stores ?? []),
              ...sellerStores,
            ],
            (item) => item.uuid
          ),
          storesListLoading: false,
          hasMoreStores: results.length >= STORE_LIST_PAGE_SIZE,
        }));
      })
      .catch((err) => {
        this.setState({
          storesListLoading: false,
          hasMoreStores: false,
        });
        if (err.status === 401 || err.status === 403) {
          User.logoutUser();
        }
      });
  };

  fetchStoreCatalogueCount = () => {
    getRequest({
      url: STORE_CATELOGUE_COUNT_URL,
    })
      .then(({ data }) =>
        this.setState({ ...convertObjKeyFromSnakeToCamelCase(data) })
      )
      .catch(console.log);
  };

  fetchLifetimeAbandonedCount = () => {
    getRequest({ url: ABANDONED_ORDER_COUNT_STATS })
      .then((res) => {
        this.setState({ allAbandonedOrdersCount: res.lifetime_count });
      })
      .catch()
      .finally(() => {});
  };

  fetchStoreAdditionalInfo = (storeId) => {
    getRequest({
      url: STORE_ADDITIONAL_INFO_URL(storeId),
    })
      .then(({ data }) =>
        this.setState({ ...convertObjKeyFromSnakeToCamelCase(data) })
      )
      .catch(console.log);
  };

  fetchBusinessDetails = async (
    callbackFn = noop,
    redirectIfNoBusiness = true,
    fetchSellerPlanLimits = false,
    redirect = true
  ) => {
    if (
      this.state.isBusinessLoading &&
      !location.pathname.includes('/account/subscription/login')
    )
      return;
    if (fetchSellerPlanLimits) {
      this.fetchSellerPlanStats();
    }

    const selectedStoreId = localStorage.getItem(SELECTED_STORE_ID_KEY);
    if (!selectedStoreId) {
      return;
    }
    this.fetchStoreCatalogueCount();
    this.fetchLifetimeAbandonedCount();
    this.fetchStoreAdditionalInfo(selectedStoreId);
    this.setState((prevProps) => ({
      ...prevProps,
      loading: true,
      isBusinessLoading: true,
    }));
    getRequest({
      url: STORE_DETAIL_URL(Number(selectedStoreId)), // pass store id here
    })
      .then(async ({ data }) => {
        if (!data) {
          // User.logoutUser(authUrls.notRegisteredPath);
          if (redirectIfNoBusiness && redirect) {
            window.location = authUrls.registerPath;
          }
        } else {
          // if reseller & not on reseller page then redirect to reseller
          if (
            data.type === RESELLER_ACCOUNT_TYPE &&
            window.location.pathname !== accountUrls.resellerSwitch
          ) {
            window.location.pathname = accountUrls.resellerSwitch;
          }

          // if not reseller but on reseller page then redirect to home
          if (
            data.type !== RESELLER_ACCOUNT_TYPE &&
            window.location.pathname === accountUrls.resellerSwitch
          ) {
            window.location.pathname = '/';
          }

          const primaryDomainDetails =
            data.domains?.[0] && data.domains?.[0]?.is_active
              ? data.domains[0]
              : null;
          const domain = primaryDomainDetails
            ? `https://${primaryDomainDetails.domain}`
            : `${APP_DOMAIN}${data.link}`;

          const domainWithLink = primaryDomainDetails
            ? `https://${primaryDomainDetails.domain}`
            : `${APP_DOMAIN}${data.link}`;
          const displayDomain = primaryDomainDetails
            ? primaryDomainDetails.domain
            : `mydukaan.io/${data.link}`;
          const canAvailFreeDukaanDelivery =
            data?.meta?.misc_data?.free_delivery === FREE_DELIVERY_AVAIL_KEY &&
            data.country === NON_INTERNATIONAL_COUNTRY_CODE &&
            data.role_name !== STAFF_ROLES.VENDOR;
          const isFashion = (data.categories || []).includes(2);
          const isPharma = (data.categories || []).includes(7);

          setItem(SELECTED_STORE_ID_KEY, data.id);

          this.setBusiness(data);
          this.setState(
            {
              canAvailFreeDukaanDelivery,
              isFashion,
              isPharma,
              domain,
              displayDomain,
              domainWithLink,
              loading: false,
              primaryDomainDetails,
              isBusinessLoading: false,
            },
            () => {}
          );
          callbackFn(data?.categories?.[0]); // pass first category to fetchCategories API
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          isBusinessLoading: false,
        });
        // user is unautorized or stores not found
        if (e.status === 401 || e.status === 404) {
          User.logoutUser();
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
          isBusinessLoading: false,
        });
      });
  };

  fetchStoreCategories = (currentCategoryId) => {
    getRequest({
      url: STORE_CATEGORIES_URL,
    })
      .then(({ results = [] }) => {
        const currentCategoryData = results.find(
          (cat) => cat.id === currentCategoryId
        );
        const allCategories = results;
        let intlCategories = [];

        const kiranaCategoryId = 1;
        const smokingCategoryId = 8;
        let kiranaCategory = results.find((cat) => cat.id === kiranaCategoryId);
        kiranaCategory = { ...kiranaCategory, name: 'FMCG & Grocery' };

        // filtering logic:
        // we add grocery category manually so we filter ir
        // we need to remove smoking category so filtering by that as well

        intlCategories = [
          kiranaCategory,
          ...results.filter(
            (cat) => cat.id !== kiranaCategoryId && cat.id !== smokingCategoryId
          ),
        ];
        this.setState({
          categories: allCategories,
          intlCategories,
          currentCategoryData,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  fetchConstants = (callbackFunc = noop) => {
    getRequest({
      url: CONSTANTS_URL,
    })
      .then(({ data }) => {
        this.setState({
          constants: data,
        });
        callbackFunc();
      })
      .catch((e) => {
        if (e.status === 401) {
          User.logoutUser();
        }
      });
  };

  switchToStore = (storeId) => {
    postRequest({
      url: STORE_TOKEN_URL,
      data: { store: storeId },
    }).then(
      ({
        data: {
          data: { access_token: accessToken },
        },
      }) => {
        setItem(SELECTED_STORE_ID_KEY, storeId);
        window.location.href = `${internalUrl.setTokenUrl}?jwt=${accessToken}`;
      }
    );
  };

  fetchAdditionalChargeDetails = (callbackFn = noop) => {
    getRequest({
      url: STORE_SERVICE_CHARGE_URL,
    })
      .then(({ results }) => {
        this.setStoreServiceCharges(results);
        callbackFn();
      })
      .catch((e) => {
        User.logoutUser(rootUrls.homePath);
        console.log(e);
      });
  };

  fetchAnnouncements = () => {
    this.setState({ announcementsLoading: true });
    getRequest({
      url: ANNOUNCEMENTS_URL,
      retries: 3,
    })
      .then(({ results, not_visited: newAnnouncements }) => {
        this.setState({ announcements: results, newAnnouncements });
      })
      .catch(() => {})
      .finally(() => this.setState({ announcementsLoading: false }));
  };

  markAnnouncementsRead = () => {
    postRequest({
      url: ANNOUNCEMENTS_URL,
    })
      .then(() => this.setState({ newAnnouncements: 0 }))
      .catch(console.log);
  };

  onMobileNumberSubmit = (
    data,
    successCB = noop,
    failureCB,
    finalCB = noop
  ) => {
    getRequest({
      url: UPDATE_NUMBER_URL,
      data: {
        mobile: data.business_mobile,
        country_code: `+${data.bussiness_phone_code}`,
        mode: SMS_MODE,
      },
    })
      .then(() => successCB())
      .catch((err) => failureCB(err))
      .finally(() => finalCB());
  };

  incrementStoresListPageNumber = (page) => {
    this.setState({
      storesListPageNumber: page,
      storesListLoading: true,
    });
  };

  setIsHelloBarActive = (val) => {
    this.setState((prevProps) => ({ ...prevProps, isHelloBarActive: val }));
  };

  render() {
    const { children } = this.props;
    const {
      authToken,
      domain,
      displayDomain,
      categories,
      currentCategoryData,
      allProductCategories,
      constants,
      announcements,
      announcementsLoading,
      newAnnouncements,
      stores,
      loading,
    } = this.state;

    // if a store id is present, user is signing in to account
    // if no store id is present, user is signing up for the first time
    let storeIds = [];
    if (authToken) {
      storeIds = parseJwt(authToken).store_ids;
    }

    const { business, sellerLimits } = this.state;
    const roleName = business?.role_name;

    const contextValue = {
      ...this.state,
      festiveDiscountPercentage: 50,
      categories,
      currentCategoryData,
      isLoggedIn: !!authToken && storeIds.length > 0,
      constants,
      domain,
      displayDomain,
      allProductCategories,
      announcements,
      newAnnouncements,
      announcementsLoading,
      stores,
      loading,
      roleName,
      setLifetimeOrderCount: this.setLifetimeOrderCount,
      setUser: this.setUser,
      setToken: this.setToken,
      setStoreId: this.setStoreId,
      setBusiness: this.setBusiness,
      setStoreServiceCharges: this.setStoreServiceCharges,
      fetchBusinessDetails: this.fetchBusinessDetails,
      fetchStoreCatalogueCount: this.fetchStoreCatalogueCount,
      fetchStoreAdditionalInfo: this.fetchStoreAdditionalInfo,
      fetchSellerPlanStats: this.fetchSellerPlanStats,
      fetchAdditionalChargeDetails: this.fetchAdditionalChargeDetails,
      setStatsDuration: this.setStatsDuration,
      setTable: this.setStoreTable,
      fetchTable: this.fetchStoreTable,
      removeTable: this.removeStoreTable,
      fetchStoreCategories: this.fetchStoreCategories,
      fetchConstants: this.fetchConstants,
      markAnnouncementsRead: this.markAnnouncementsRead,
      fetchAnnouncements: this.fetchAnnouncements,
      incrementStoresListPageNumber: this.incrementStoresListPageNumber,
      onMobileNumberSubmit: this.onMobileNumberSubmit,
      setIsHelloBarActive: this.setIsHelloBarActive,

      fetchStores: this.fetchStores,
      isOwner: roleName === STAFF_ROLES.OWNER,
      isAdmin: roleName === STAFF_ROLES.ADMIN,
      isManager: roleName === STAFF_ROLES.MANAGER,
      isManagerL1: roleName === STAFF_ROLES.MANAGER_L1,
      isManagerL2: roleName === STAFF_ROLES.MANAGER_L2,
      isStaff: roleName === STAFF_ROLES.STAFF,
      isStaffL1: roleName === STAFF_ROLES.STAFF_L1,
      isStaffL2: roleName === STAFF_ROLES.STAFF_L2,
      isStaffL3: roleName === STAFF_ROLES.STAFF_L3,
      isVendor: roleName === STAFF_ROLES.VENDOR,
      isAppSumoStore: sellerLimits?.is_appsumo_activated,
      isOldAppSumoStore:
        sellerLimits?.is_appsumo_activated &&
        !sellerLimits?.is_appsumo_activated_v2,
      canCreateVendor: business.meta?.misc_data?.can_create_vendor ?? false,
      // first ever store created in multistore flow
      isPrimaryStore: business?.id === stores[0]?.id,
      switchToStore: this.switchToStore,
    };

    return (
      <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
    );
  }
}

export default AppProvider;
