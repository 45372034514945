import React from 'react';
import {
  D2C_APPEARENCE_THEME,
  LEO,
  LIGHTSPEED_APPEARENCE_THEME,
  LUXURY,
  MATRIX_THEME,
} from '../Themes/constants';

export const tabsList = [
  { label: 'Description', value: 'desc' },
  { label: 'Installation Guide', value: 'guide' },
  { label: 'FAQ', value: 'faq' },
];

export const FAQ_ID = '634401973d4fbe76d450e5a4';
export const SHOPIFY_IMPORTER_APP_ID = '627ccee39602da0675bd29f0';
export const WHATSAPP_ACCOUNT_APP_ID = '62b9b5a1fea86f77d7f0d468';
export const RAZORPAY_APP_ID = '6058b8978ce211725761dc46';
export const REVIEWS_APP_NAME = 'Product Reviews and Ratings';
export const STORE_LOCATOR_APP_ID = '6286104b4f1ca25e2256f6b7';
export const PRODUCT_SCARCITY_ID = '62e2290734db4f8462f4fc37';
export const DUPLICATE_ORDER_ID = '62eb6b07f106e8b9cb0506ba';
export const PINCODE_APP_ID = '60dfea29ce59843086b7e13c';
export const REVIEWS_APP_ID = '60f69a8a4197f652c03e4c59';
export const REVIEWS_IMPORTER_APP_ID = '624fe0a39e71b2377da2e264';
export const SEO_APP_ID = '60b5de77e3320a1125460a88';
export const CUSTOM_SCRIPT_INSERTER_APP_ID = '62f203b85191ee45f1383776';
export const SHIPPO_ID = '61a470a59f3723f98f2e3d2d';
export const TRUSTED_BADGES_ID = '620e470f344eb2e50d47d64b';
export const SIZE_CHART = '6286276b4f1ca25e2256f6ba';
export const RTO_OPTIMISER_PLUGIN_ID = '62ac26b34a8f25771c6ea75d';
export const NUDGIFY_ID = '62821ed0a426ba46fa3ce378';
export const MAIL_MUNCH_ID = '62823edea426ba46fa3ce37d';
export const BAG_CONTROL_ID = '6286273a4f1ca25e2256f6b9';
export const COOKIE_YES_ID = '6282ae6aa426ba46fa3ce3af';
export const WISHLIST_PLUGIN_ID = '62862d034f1ca25e2256f6bb';
export const USER_LIKE_ID = '62825820a426ba46fa3ce3aa';
export const PRODUCT_ATTRIBUTES_PLUGIN_ID = '62fcbe3a3334c51897f4f0bb';
export const ADV_COUNTDOWN_TIMER_APP_ID = '62e2281b34db4f8462f4fc36';
export const HELLOBAR_V2_ID = '62f34e205191ee45f1383777';
export const WOO_COMMERCE_IMPORTER_ID = '6316039a30de7d631050fa49';
export const GOOGLE_MERCHANT_CENTER_PLUGIN_ID = '6142d40ed6d9358b23ac5cdc';
export const LOYALTY_POINTS_PLUGIN_ID = '631f19c77f672212f37bf651';
export const STOCK_OVERRIDE_ID = '6320526c45a561f0e7363064';
export const BRAND_INFORMATION_PLUGIN_ID = '633ae595f24496b82b8532a4';
export const WEBHOOKS_PLUGIN_ID = '633819ba63656518b33249f8';
export const SERVER_EVENTS_PLUGIN = '636cd36955ad1433e75d37b0';
export const DIGITAL_PRODUCTS_PLUGIN = '62f686bc8ae5ac202abc2052';
export const GUMROAD_PRODUCT_IMPORTER_ID = '63a18ca804cc41153f89cd1c';
export const UPSELL_CROSSSELL_PLUGIN_ID = '63ea1e9a16679320ed02562b';
export const URL_REDIRECT_PLUGIN_ID = '64a27655987dd9354c6383b1';
export const UNICOMMERCE_PLUGIN_ID = '64808fd23dba9e0283b2f51f';
export const EASYECOM_PLUGIN_ID = '64809123b0a04302bd5a1634';

export const faq = [
  {
    question: 'What kind of businesses can use Dukaan?',
    answer:
      'Any kind of business who is selling any products or services can use MyDukaan to accept orders from their customers online.',
  },
  {
    question: 'Do you charge commission on orders?',
    answer:
      'Any kind of business who is selling any products or services can use MyDukaan to accept orders from their customers online.',
  },
  {
    question: 'Will you deliver my orders?',
    answer:
      'Any kind of business who is selling any products or services can use MyDukaan to accept orders from their customers online.',
  },
  {
    question: 'Will you provide support?',
    answer:
      'Any kind of business who is selling any products or services can use MyDukaan to accept orders from their customers online.',
  },
];

export const carousel = [
  '/images/networkImage.jpg',
  '/images/mast.jpg',
  '/images/phone.jpg',
];

export const storeTimingInitialData = [
  {
    day: 'sunday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
  {
    day: 'monday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
  {
    day: 'tuesday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
  {
    day: 'wednesday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
  {
    day: 'thursday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
  {
    day: 'friday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
  {
    day: 'saturday',
    from_time: '10:00:00',
    online: true,
    to_time: '22:00:00',
  },
];

export const STORE_LOCATOR_DEFAULT_START_TIME = '10:00:00';
export const STORE_LOCATOR_DEFAULT_END_TIME = '22:00:00';

export const WHATSAPP_ACCOUNT_PLUGIN_STATUS = {
  APPROVAL_PENDING: 'pending',
  APPROVED: 'active',
};

export const WHATSAPP_ACCOUNT_PLUGIN_FAQ = [
  {
    query: 'What is cost per order?',
    answer: (
      <>
        <p>
          When a customer purchases a product from your store, we send a series
          of messages to them as WhatsApp updates. These include messages about
          order confirmation, order acceptance, shipping status, tracking link,
          etc.
        </p>
        <p className="pt8">
          The cost for all such messages for 1 order is the cost per order.
        </p>
      </>
    ),
  },
  {
    query: 'How are monthly active users counted?',
    answer: (
      <>
        <p>
          Monthly active users is calculated when you have at least one message
          (incoming message or outgoing message) with a user in a month.
        </p>
        <p className="pt8">
          The count is reset at the end of each month. You can have any number
          of messages with a user in that month.
        </p>
      </>
    ),
  },
  {
    query: 'What if I cross the monthly user limit?',
    answer:
      'You can choose to upgrade to the next plan manually before the month-end or you will be charged Rs. 750 per 1000 additional monthly active users.',
  },
  {
    query: 'What is WhatsApp verified green tick?',
    answer:
      "When you choose to whitelabel your WhatsApp number, you will get a WhatsApp verified green tick for business on your account. The green badge next to the contact's name confirms that this is an authentic and notable business account.",
  },
  {
    query: 'What is the broadcasting feature?',
    answer:
      'The broadcasting feature allows you to send a message or media to several customers at once. The broadcast message will appear to be an individual message from you.',
  },
];

export const WHATSAPP_ACCOUNT_PLUGIN_DEAFULT_TOPUP = 250;

export const WHATSAPP_ACCOUNT_PREDEFINED_TOPUP_VALUES = [100, 500, 1000, 5000];

export const WHITELABEL_WHATSAPP_TYPE_STATUS = {
  debit: {
    label: 'Debit',
    color: 'error',
  },
  credit: {
    label: 'Credit',
    color: 'successful',
  },
};

export const WHITELABEL_WHATSAPP_MINIMUM_BALANCE_WARNING = 100;

export const WHATSAPPCOMMS_PLUGIN_TABLE_HEADINGS = [
  'Order ID',
  'Date',
  'Cost',
  'Type',
  'Balance',
];

export const WHITELABEL_WHATSAPP_GOLD_SCHEDULE_CALENDLY =
  'https://calendly.com/salvin/30min'; // check with neel

export const WISHLIST_SKU_COLOR_ATTRIBUTE = 'color';

export const THEME_DISABLED_PLUGIN_CONFIG = {
  [LIGHTSPEED_APPEARENCE_THEME]: [WISHLIST_PLUGIN_ID],
  [LEO]: [WISHLIST_PLUGIN_ID],
  [MATRIX_THEME]: [[WISHLIST_PLUGIN_ID]],
  [D2C_APPEARENCE_THEME]: [WISHLIST_PLUGIN_ID],
  [LUXURY]: [WISHLIST_PLUGIN_ID],
};

export const WISHLIST_DISABLED_THEMES = [
  LIGHTSPEED_APPEARENCE_THEME,
  LEO,
  MATRIX_THEME,
  D2C_APPEARENCE_THEME,
  LUXURY,
];

export const ENTERPRISE_PLUGIN_STATUS = 'enterprise';

export const EXPIRATION_OPTIONS = [
  { label: 'Never', value: 0 },
  { label: '15 days', value: 15 },
  { label: '1 month', value: 30 },
  { label: '2 months', value: 60 },
  { label: '3 months', value: 90 },
  { label: '6 months', value: 180 },
  { label: '1 year', value: 365 },
];
