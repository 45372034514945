export const WEBVIEW_INJECTED_JAVASCRIPT_OBJECT = {
  isRNWebView: 'isRNWebView',
  isIOS: 'isIOS',
  isAndroid: 'isIOS',
  storeId: 'storeId',
  userDetail: 'userDetail',
  authToken: 'authToken',
  insets: 'insets',
  staffRole: 'staffRole',
  meta: 'meta',
};

export const WEBVIEW_MESSAGES = {
  HAPTIC_FEEDBACK: {
    key: 'HAPTIC_FEEDBACK',
  },
  BACK: {
    key: 'BACK',
  },
  LOGOUT: {
    key: 'LOGOUT',
  },
  OPEN_IN_APP_BROWSER: {
    key: 'OPEN_IN_APP_BROWSER',
    url: '',
  },
  OPEN_WEBVIEW: {
    key: 'OPEN_WEBVIEW',
    title: '',
    uri: '',
  },
  INVALIDATE_QUERY: {
    key: 'INVALIDATE_QUERY',
    queryKey: '',
  },
  PRESENT_ADD_CREDITS_BOTTOM_SHEET_MODAL: {
    key: 'PRESENT_ADD_CREDITS_BOTTOM_SHEET_MODAL',
  },
};
