import { useState } from 'react';
import {
  GET_STORE_ORDER_SETTINGS,
  PINCODE_CHECKER_DATA,
  PRODUCT_SKU_PAYMENT_MODE_PLUGIN_MAPPING,
  UPDATE_STORE_ORDER_SETTINGS,
} from '../ApiUrls';
import { getErrorMessage, noop } from '../utils';
import { getRequest, patchRequest, postRequest } from '../utils/http';
import useCustomSnackbar from './useCustomSnackbar';
import useFileDownload from './useFileDownload';

const useStoreOrderSettings = () => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [storeOrderSettings, setStoreOrderSettings] = useState({});
  const [codDisabledProducts, setCodDisabledProducts] = useState([]);

  const { downloadFile } = useFileDownload();

  const handleStoreOrderSettingsError = (error) => {
    const errorText = getErrorMessage(error?.data?.data?.errors);
    enqueueSnackbar(errorText, 'error');
    console.log(error);
  };

  const getStoreOrderSettings = (successCallback = noop) => {
    setLoading(true);

    getRequest({
      url: GET_STORE_ORDER_SETTINGS,
    })
      .then((data) => {
        setStoreOrderSettings(data?.data);
        successCallback(data?.data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const createStoreOrderSettings = (payload, successCallback = noop) => {
    setLoading(true);

    postRequest({
      url: GET_STORE_ORDER_SETTINGS,
      data: { ...payload },
      doClean: false,
    })
      .then((data) => {
        setStoreOrderSettings(data?.data);
        successCallback(data?.data);
        enqueueSnackbar('Values updated successfully!');
      })
      .catch(handleStoreOrderSettingsError)
      .finally(() => setLoading(false));
  };

  const updateStoreOrderSettings = (
    uuid,
    payload,
    successCallback = noop,
    showSnackbar = true
  ) => {
    patchRequest({
      url: UPDATE_STORE_ORDER_SETTINGS(uuid),
      data: { ...payload },
      doClean: false,
    })
      .then((data) => {
        setStoreOrderSettings(data?.data);
        successCallback(data?.data);
        if (showSnackbar) {
          enqueueSnackbar('Values updated successfully!');
        }
      })
      .catch(handleStoreOrderSettingsError)
      .finally(() => setLoading(false));
  };

  const getCodDisabledProducts = (successCallback = noop) => {
    getRequest({
      url: PRODUCT_SKU_PAYMENT_MODE_PLUGIN_MAPPING,
    })
      .then((data) => {
        setCodDisabledProducts(data?.data?.cod_disabled_product_sku_ids);
        successCallback(data?.data?.cod_disabled_product_sku_ids);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const downloadPincodeDataFile = () => {
    getRequest({
      url: PINCODE_CHECKER_DATA,
    })
      .then((data) => {
        downloadFile(data?.data?.file_url, true); // set this somewhere
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const uploadPincodeDataFile = (file) => {
    setSubmitting(true);

    const formData = new FormData();
    formData.append('pincode_to_payment_mode_csv_file', file);

    postRequest({
      url: PINCODE_CHECKER_DATA,
      data: formData,
    })
      .then(() => {
        enqueueSnackbar('Values updated successfully!');
      })
      .catch(console.log)
      .finally(() => setSubmitting(false));
  };

  const updateCodDisabledProducts = (payload, successCallback = noop) => {
    setSubmitting(true);
    postRequest({
      url: PRODUCT_SKU_PAYMENT_MODE_PLUGIN_MAPPING,
      data: { cod_disabled_product_sku_ids: payload },
      doClean: false,
    })
      .then((data) => {
        getCodDisabledProducts();
        successCallback(data?.data);
        enqueueSnackbar('Values updated successfully!');
      })
      .catch(handleStoreOrderSettingsError)
      .finally(() => setSubmitting(false));
  };

  return {
    loading,
    submitting,
    setLoading,
    storeOrderSettings,
    getStoreOrderSettings,
    createStoreOrderSettings,
    updateStoreOrderSettings,
    codDisabledProducts,
    getCodDisabledProducts,
    updateCodDisabledProducts,
    downloadPincodeDataFile,
    uploadPincodeDataFile,
  };
};

export default useStoreOrderSettings;
