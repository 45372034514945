import React from 'react';

const RazorpayCtaButton = () => (
  <>
    <div className="logo-container">
      <img
        src="https://web.mydukaan.io/images/dukaan-pay/dukaan-pay.svg"
        alt="DukaanPay"
        className="logo"
      />
      <svg
        className="arrow"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 12H20M20 12L14 6M20 12L14 18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/8/89/Razorpay_logo.svg"
        alt="Razorpay"
        className="logo razorpay-logo"
      />
    </div>
    <span>MIGRATE NOW</span>
  </>
);

export default RazorpayCtaButton;
