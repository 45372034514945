import { STAGING_SELLER_LINK } from '../constants';
import { minMaxLengthValidation } from '../shared/Form/Validation';

export const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 20;

export const DEFAULT_PASSWORD_PROPS = {
  type: 'password',
  minLength: MIN_PASSWORD_LENGTH,
  maxLength: MAX_PASSWORD_LENGTH,
  validate: minMaxLengthValidation(MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH),
};

export const WHITELISTED_ADDRESSES = [
  'web.mydukaan.io',
  STAGING_SELLER_LINK,
  'localhost',
  'netlify.app',
  'vercel.app',
];
