import { STORE_SETTINGS_SECTION } from './Account/constants';

export const authUrls = {
  loginPath: '/login',
  notRegisteredPath: '/not-registered',
  registerPath: '/getting-started',
  registerSuccessPath: '/registered',
  forgotPasswordPath: '/forgot-password',
  selectStorePath: '/select-store',
};

export const catalogueUrls = {
  cataloguePath: '/catalogue/:tabId',
  newProductPath: '/catalogue/products/new',
  newProductBulkPath: '/catalogue/products/bulk',
  productFiltersPath: '/catalogue/products/filters',
  productBulkPricesPath: '/catalogue/inventory/bulkprices',
  productInventoriesBulkUpdatePath: '/catalogue/inventory/bulk/update',
  editProductPath: '/catalogue/products/:uuid/edit',
  newCategoryPath: '/catalogue/categories/new',
  newSubcategoryPath: '/catalogue/categories/subcategory/new',
  selectCatalogCategory: '/catalogue/products/select-category',
  selectCatalogProducts: '/catalogue/products/select-products',
  singleCategoryProductsPath: '/catalogue/categories/:categoryId/products',
  editCategoryPath: '/catalogue/categories/:uuid/:categoryId/edit',
  editSubcategoryPath:
    '/catalogue/categories/:uuid/:categoryId/edit/subcategory',
  attributesPath: '/catalogue/attributes',
  attributesCreatePath: '/catalogue/attributes/create',
  reorderAttributesPath: '/catalogue/attributes/reorder',
  editAttributes: '/catalogue/attributes/edit/:attributeId',
  bundlesPath: '/catalogue/bundles',
  newBundlePath: '/catalogue/bundles/new',
  editBundlePath: '/catalogue/bundles/:uuid/edit',
};

export const marketingUrls = {
  marketingPath: '/tools/marketing/',
  viewDesigns: '/tools/marketing/designs/:type',
  whatsAppDesigns: '/tools/marketing/whatsapp-stories',
  businessCards: '/tools/marketing/business-cards',
  storeBanners: '/tools/marketing/store-banners',
};

export const restaurantUrls = {
  viewRestaurant: '/tools/restaurant',
  viewTable: '/tools/restaurant/table',
  tableOrders: '/tools/restaurant/:uuid/orders',
  addTable: '/tools/restaurant/table/add',
};

export const accountUrls = {
  accountPath: '/account',
  settingsPath: '/settings',
  paymentsSectionPath: '/settings?section=payments',
  shippingRulesWarehousePath: '/settings/shipping-rules/warehouse',
  shippingRulesConnectPartnersPath: '/settings/shipping-rules/connect-partners',
  shippingRulesPartnerPriorityPath: '/settings/shipping-rules/partner-priority',
  categoryAttributes: '/account/category-attributes',
  updatePhoneNumberUrl: '/account/update-phone-number',
  createAttributes: '/account/attributes/create',
  editAttributes: '/account/attributes/edit/:attributeId',
  reorderAttributes: '/account/attributes/reorder',
  editBusinessDetailsPath: '/account',
  updateSuccessPath: '/account/success',
  updateAadharSuccessPath: '/account/aadhar-success',
  additionalInformationPath: '/account/additional-information',
  deleteAccount: '/account/delete-account',
  accountDeleted: '/account/account-deleted',
  storeManagementPath: '/account/store-management',
  subscriptionPath: '/account?section=subscription',
  subscriptionSuccessPath: '/account/subscription/success',
  subscriptionPurchasePath: '/account/subscription/purchase',
  subscriptionPurchaseMobilePath: '/account/subscription/purchase-mobile',
  subscriptionPurchaseMobileSuccess: '/account/subscription/mobile/success',
  subscriptionPurchaseMobilePlanLoginPath: '/account/subscription/login',
  subscriptionCancelPath: '/account/subscription/cancel',
  customDomain: '/account/custom-domain',
  customDomainPremiumDomains: '/account/custom-domain?section=premium-domains',
  customDomainSelectDomain: '/account/custom-domain?sd=1',
  customDomainPending: '/account/custom-domain/pending',
  customDomainExisting: '/account/custom-domain/existing',
  customDomainSuccess: '/account/custom-domain/success',
  getStoreApp: '/tools/get-store-app',
  staffAccountVerifySuccess: '/staff-verify-success',
  staffAccountVerifyError: '/staff-verify-error',
  resellerSwitch: '/reseller',
  planDowngradePath: '/account/subscription/downgrade/causes',
  staffAccountAccessRevoked: '/staff-access-revoked',
  transactionFeeForOrders: '/invoice/:uuid',
};

export const rootUrls = {
  homePath: '/',
  ordersPath: '/orders',
  returnOrdersPath: `/return-orders`,
  returnOrderDetailsPath: `/return-orders/:uuid`,
  abandonedOrdersPath: '/abandoned-orders',
  abandonedOrdersAdvanceSettings: '/abandoned-orders/advance-settings',
  successRecoveryMessageBroadcastPath:
    '/abandoned-orders/success-recovery-message-broadcast',
  deliveryPath: '/delivery',
  ordersMultipleSelectPartnersPath: '/orders/select-multiple-partners',
  ordersTrackingPath: '/orders/shipment-tracking',
  ordersMultipleShipPath: '/orders/ship-multiple',
  orderHyperlocalPath: '/orders/select-hyperlocal-orders',
  ordersMultipleShipConfirmPath: '/orders/ship-multiple/confirm',
  abandonedOrderDetailsPath: '/abandoned-orders/:uuid',
  orderDetailsPath: '/orders/:uuid',
  orderEditPath: '/orders/:uuid/edit',
  orderSelfShipPath: '/orders/:uuid/self-ship',
  orderDeliveryIntlPartnerPath: '/orders/:uuid/shipping-partner-intl',
  orderDeliveryPartnerPath: '/orders/:uuid/shipping-partner',
  orderReturnPickupPartnerPath: '/orders/:uuid/return/shipping-partner',
  orderReplacementPickupPartnerPath:
    '/orders/:uuid/replacement-pickup/shipping-partner',
  orderReplacementForwardPartnerPath:
    '/orders/:uuid/replacement-forward/shipping-partner',
  orderDeliveryShipmentWeightPath:
    '/orders/:uuid/shipping-partner/shipment-weight',
  orderDeliveryCostPath: '/orders/:uuid/shipping-partner/confirm-delivery-cost',
  orderReturnPickupCostPath:
    '/orders/:uuid/return/shipping-partner/confirm-delivery-cost',
  orderReplacementPickupCostPath:
    '/orders/:uuid/replacement-pickup/shipping-partner/confirm-delivery-cost',
  orderReplacementForwardCostPath:
    '/orders/:uuid/replacement-forward/shipping-partner/confirm-delivery-cost',
  intlOrderDeliveryCostPath: '/orders/:uuid/confirm-delivery-intl',
  calculateShipmentPricePath: '/orders/:uuid/calculate-shipment-price',
  notAvailablePath: '/not-available',
  managePath: '/tools',
  pages: '/pages',
  createOrder: '/create-order',
  commissionReportPath: '/commission-report',
  viewAllReportsPath: '/view-all-reports',
};

export const appearanceUrls = {
  themes: '/appearance/themes',
  customizeTheme: '/appearance/themes/customize',
  menu: '/appearance/menu',
  codeEditor: '/appearance/themes/customize/code-editor',
};

export const campaignUrls = {
  home: '/marketing',
  campaignDetails: '/marketing/:id',
  credits: '/credits',
  addCreditsSuccess: '/credits/success',
  addCreditsFailure: '/credits/failure',
  createCampaign: '/marketing/create-campaign',
  createCampaignSuccess: '/marketing/create-campaign/success',
  wishlistSuccessMessagePath: '/wishlist/success-message-broadcast',
  emailMarketingEditor: '/email-template-editor',
};

export const analyticsUrl = {
  businessOverviewPath: '/analytics/business-overview',
  ordersReport: '/analytics/business-overview/report',
  salesReport: '/analytics/business-overview/sales-report',
  salesByRegionReport: '/analytics/business-overview/sales-by-region-report',
  salesByTrafficSource: '/analytics/business-overview/sales-by-traffic-report',
  storeOverviewPath: '/analytics/store-overview',
  storeOverviewReport: '/analytics/store-overview/store-overview-report',
  storeSessionsReport: '/analytics/store-overview/store-sessions-report',
  storeSessionsByPageReport:
    '/analytics/store-overview/store-sessions-overview-report',
  storeSessionsByUTMReport:
    '/analytics/business-overview/store-sessions-by-utm-overview-report',
  productOverviewPath: '/analytics/product-overview',
  productViewsReport: '/analytics/product-overview/product-views-report',
  salesByCategoryReport: '/analytics/product-overview/sales-by-category-report',
  salesByProductReport: '/analytics/product-overview/sales-by-product-report',
};

export const referralUrls = {
  home: '/referral',
  successfullReferrals: '/referral/successful',
};

export const manageUrls = {
  paymentsUrl: '/payments',
  setupPaymentsUrl: '/payments/setup',
  paymentVerificationSuccessUrl: '/payments/success',
  updatePaymentDetail: '/payments/update',
  couponsPath: '/coupons',
  newCouponPath: '/coupons/new',
  editCouponPath: '/coupons/:uuid/edit',
  intlSalesTaxPath: `/settings?section=${STORE_SETTINGS_SECTION.INTLTAX}`,
  deliveryChargesPath: `/settings?section=${STORE_SETTINGS_SECTION.EXTRA_CHARGES}`,
  multiWarehousePath: `/settings?section=${STORE_SETTINGS_SECTION.WAREHOUSE}`,
  gstTaxPath: `/settings?section=${STORE_SETTINGS_SECTION.TAX}`,
  domainSettingsPath: `/settings?section=${STORE_SETTINGS_SECTION.DOMAINS}`,
  multiWarehouseSettingsPath: `/delivery/multiwarehouse`,
  storeQRCodePath: '/tools/store-qr',
  backgroudRemoverPath: '/tools/background-remover',
  imageEnhancerPath: '/tools/image-enhancer',
  productDescriptionPath: '/tools/product-description',
  hsnCodeFinder: '/tools/hsn-code-finder',
  customersPath: '/customers',
  customerPath: '/customers/:id/orders',
  orderForm: '/account/order-form',
  orderFormNewField: '/account/order-form/new',
  orderFormEditField: '/account/order-form/:uuid',
  invoiceGeneratorPath: '/tools/invoice-generator',
  storeTimingsPath: '/manage/store-timings',
  dukaanDelivery: '/delivery',
  editDukaanDelivery: '/delivery/edit',
  connectIntlPartners: '/delivery/edit/connect',
};

export const internationPaymentsUrl = {
  razorpayAuthUrl: '/payments/authorize-razorpay',
};

export const pluginsUrls = {
  installedPluginsPath: '/plugins/installed',
  pluginsPath: '/plugins/all',
  pluginDetailsPath: '/plugins/:uuid',
  pluginSettingPath: '/plugins/:uuid/settings',
  dukaanPlusPath: '/plugins/:uuid/dukaan-plus',
  pluginDescPath: '/plugins/:uuid/desc',
  pluginReviewPath: '/plugins/:uuid/review',
  pluginGuidePath: '/plugins/:uuid/guide',
  pluginFAQPath: '/plugins/:uuid/faq',
  storeLocatorPath: '/plugins/offline-store-locator',
  whiteLabelWhatsapp: '/plugins/whitelabel/subscription',
};

export const testModalsUrl = {
  testPath: '/testmodals',
};
export const pagesUrls = {
  pages: '/pages',
  addPage: '/pages/add',
  viewPage: '/pages/:uuid',
};

export const blogUrls = {
  blog: '/blog',
  addBlog: '/blog/add',
  viewBlog: '/blog/:uuid',
};

export const mediaLibraryUrls = {
  media: '/media',
  editMedia: '/media/:uuid',
};

export const internalUrl = {
  setTokenUrl: '/token',
  sendStoreIDUrl: '/send-store-id',
  onetapLoginUrl: '/onetap-login',
};

export const tutorialUrls = {
  tutorials: '/tutorials',
};

export const storeSettings = {
  preferences: `/settings?section=${STORE_SETTINGS_SECTION.PREFERENCES}`,
  payments: `/settings?section=${STORE_SETTINGS_SECTION.PAYMENTS}`,
  warehouse: `/settings?section=${STORE_SETTINGS_SECTION.WAREHOUSE}`,
  shipping: `/settings?section=${STORE_SETTINGS_SECTION.SHIPPING}`,
};

export const externalLoginPaths = [
  ...Object.values(internalUrl),
  accountUrls.subscriptionPurchaseMobilePlanLoginPath,
];

export const SELLER_NEXT = {
  PAYMENTS:
    'https://seller-next-git-payments-api-integration-dukaan-tech.vercel.app/settings?section=payments',
};
