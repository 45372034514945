import mixpanel from 'mixpanel-browser';

import Cookies from 'js-cookie';

import {
  setDataToLocalStorage,
  getDataFromLocalStorage,
  setItem,
  getItem,
  clearStorage,
  clearSessionStorage,
  setSessionItem,
  getSessionItem,
} from './storage';
import { authUrls } from '../Urls';
import { getInjectedJavascriptValueFromWebView } from './webview';
import { WEBVIEW_INJECTED_JAVASCRIPT_OBJECT } from '../webview';

const USER_DETAIL_KEY = 'sl_usr_dl';
export const AUTH_TOKEN_KEY = 'sl_at_to';
const REFRESH_TOKEN_KEY = 'sl_ref_to';
export const COOKIE_AUTH_TOKEN_KEY = 'auth_token';

export const setUserDetails = (details) => {
  setDataToLocalStorage(USER_DETAIL_KEY, details);
};

export const getUserDetails = () => getDataFromLocalStorage(USER_DETAIL_KEY);

export const setAuthToken = (token) => {
  setItem(AUTH_TOKEN_KEY, token);
  setSessionItem(AUTH_TOKEN_KEY, token);
  Cookies.set(COOKIE_AUTH_TOKEN_KEY, token);
};

export const getAuthToken = () => {
  const cookieAuthToken = Cookies.get(COOKIE_AUTH_TOKEN_KEY);

  const authTokenForWebView = getInjectedJavascriptValueFromWebView(
    WEBVIEW_INJECTED_JAVASCRIPT_OBJECT.authToken
  );

  return (
    authTokenForWebView ||
    cookieAuthToken ||
    getItem(AUTH_TOKEN_KEY) ||
    getSessionItem(AUTH_TOKEN_KEY)
  );
};

export const setRefreshToken = (token) => setItem(REFRESH_TOKEN_KEY, token);

export const getRefreshToken = () => getItem(REFRESH_TOKEN_KEY);

export const isUserLoggedIn = () => !!getAuthToken();

export const logoutUser = () => {
  try {
    mixpanel.reset();
    window.google.accounts.id.disableAutoSelect();
  } catch (error) {
    console.log(error);
  }
  clearStorage();
  clearSessionStorage();
  Cookies.remove(COOKIE_AUTH_TOKEN_KEY);
  window.location = `${authUrls.loginPath}?gs=0`;
};
