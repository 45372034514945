import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';

import {
  FormGroup,
  FormInput,
  FormNumberInput,
  Validation,
} from '../../shared/Form';
import { getRequest, postRequest } from '../../utils/http';
import { ExternalLink } from '../../shared';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import Nudge from '../../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';
import {
  SAVE_PAYMENT_CREDENTIALS_OTP,
  SAVE_PAYMENT_GATEWAY_CREDS,
} from '../../ApiUrls';
import { PAYMENT_GATEWAY_IDS } from '../Payments/constants';
import { PHONEPE_KEY, usePayments } from '../../context/PaymentsProvider';
import { parseSellerUsername } from '../../utils/string';
import useScript from '../../hooks/useScript';
import { RECAPTCHA_SITE_KEY } from '../../constants';
import './InputForm.scss';

const PHONEPE_HELP_URL = 'https://business.phonepe.com/login';
const PHONEPE_CREATE_ACCOUNT_URL =
  'https://www.phonepe.com/business-solutions/payment-gateway/register/';

const OTP_INTERVAL = 30000;

const PhonepeSetupForm = ({
  isActivated = false,
  isPresent = false,
  successCallback = noop,
}) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { fetchPaymentGatewayCreds, paymentKeys } = usePayments();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialCreds, setInitialCreds] = useState({
    salt_key: '',
    salt_index: '',
    merchant_id: '',
    otp: '',
    reason: 'save_payment_credentials',
  });
  const [OTPSent, setOTPSent] = useState(false);
  const otpRef = useRef(null);

  const [timerDate, setTimerDate] = useState(Date.now() + OTP_INTERVAL);

  const { isPhoneNumber, countryCode, mobileNumber, email } =
    parseSellerUsername(business?.role_username);

  useScript(
    'https://www.google.com/recaptcha/api.js?render=6Le1AaQjAAAAAG7VprkQcNmuuQsqDM93Yu_v7yNk'
  );

  const handleSendOTP = () => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
        .then((token) =>
          getRequest({
            url: SAVE_PAYMENT_CREDENTIALS_OTP,
            data: { hashcode: token },
          }).then((resp) => {
            if (resp.status === 'success') {
              setOTPSent(true);
              setTimerDate(Date.now() + OTP_INTERVAL);
            }
          })
        );
    });
  };

  const onResendOtpLink = () => {
    setTimerDate(Date.now() + OTP_INTERVAL);
    handleSendOTP();
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: SAVE_PAYMENT_GATEWAY_CREDS(
        business.uuid,
        PAYMENT_GATEWAY_IDS.PHONEPE
      ),
      data: {
        salt_key: values.salt_key,
        salt_index: values.salt_index,
        payment_provider_account_meta: { merchant_id: values.merchant_id },
        otp: values.otp,
        reason: 'save_payment_credentials',
      },
    })
      .then(() => {
        enqueueSnackbar('PhonePe Payments set up successfully!');
        successCallback();
        fetchBusinessDetails();
      })
      .catch((err) => {
        if (err?.data?.data?.otp?.length > 0) {
          enqueueSnackbar(err?.data?.data?.otp?.[0], 'error');
        } else {
          enqueueSnackbar(
            'We are unable to verify your keys. Please enter correct key values.',
            'error'
          );
        }
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (isPresent) {
      setLoading(true);
      fetchPaymentGatewayCreds(
        paymentKeys.find((it) => it.provider === PHONEPE_KEY).uuid,
        (data) => {
          const creds = data?.data?.payment_provider_account;
          if (creds) {
            setInitialCreds({
              salt_key: creds?.client_secret,
              salt_index: creds?.client_id,
              merchant_id: creds?.payment_provider_account_meta?.merchant_id,
            });
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialCreds }}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({
        form: {
          mutators: { setValue },
        },
        handleSubmit,
        invalid,
        values,
      }) => (
        <form className="mt4">
          {!isPresent && (
            <>
              <div className="text-8 mb16">
                Please enter your PhonePe. You can find your credentials on
                <ExternalLink
                  href={PHONEPE_HELP_URL}
                  className="text-medium c-purple-1-i ml6 underline"
                >
                  PhonePe Dashboard
                </ExternalLink>
              </div>
              <Nudge
                variant={NUDGE_VARIANTS.PRIMARY}
                type={NUDGE_TYPES.PRIMARY}
                iconVariant={NUDGE_ICON_VARIANTS.BLUE}
                text={
                  <p className="c-black-3 text-8 d-flex align-center">
                    Don't have an PhonePe account?{' '}
                    <ExternalLink
                      href={PHONEPE_CREATE_ACCOUNT_URL}
                      className="text-medium c-black-3-i ml6 underline"
                    >
                      Create now
                    </ExternalLink>
                  </p>
                }
              />
            </>
          )}

          <FormInput
            labelText="Salt Key"
            name="salt_key"
            placeholder="Enter Salt Key"
            className="my24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Salt Index"
            name="salt_index"
            placeholder="Enter Salt Index"
            className="my24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Merchant Id"
            name="merchant_id"
            placeholder="Enter Merchant Id"
            className="mb24"
            required
            showRequired
            validate={Validation.required()}
          />
          <div className="mt20">
            {!OTPSent && (
              <div className="delete-verify-account">
                <div className="full-w">
                  {isPhoneNumber ? (
                    <FormNumberInput
                      name="phone"
                      labelText="VERIFY YOUR MOBILE NUMBER"
                      placeholder="0123456789"
                      required
                      prependText={countryCode}
                      largePrependText
                      disabled
                      initialValue={mobileNumber}
                    />
                  ) : (
                    <FormInput
                      name="email"
                      value={email}
                      labelText={
                        <p className="field-label-text">
                          VERIFY YOUR EMAIL ADDRESS
                        </p>
                      }
                      required
                      disabled
                    />
                  )}
                </div>
                <SpinnerButton
                  type="button"
                  className="btn-outline-primary otp-button mt6"
                  onClick={handleSendOTP}
                >
                  Get OTP
                </SpinnerButton>
              </div>
            )}

            {OTPSent && (
              <div className="otp-verfication-form">
                <div className="otp-form-content">
                  <FormGroup
                    labelText="ENTER THE OTP"
                    className="otp-input-wrap mt24 mb24"
                  >
                    <OtpInput
                      ref={otpRef}
                      value={values.otp}
                      onChange={(value) => {
                        setValue('otp', value);
                      }}
                      numInputs={6}
                      isInputNum
                      shouldAutoFocus
                      className="mr10"
                    />
                  </FormGroup>
                </div>

                <Countdown
                  key={timerDate}
                  date={timerDate}
                  renderer={({ seconds, completed }) => {
                    if (completed) {
                      return (
                        <div className="resend-text-container">
                          <span
                            className="resend-otp-link anchor-1 resend-text-container"
                            onClick={onResendOtpLink}
                          >
                            Resend Code
                          </span>
                        </div>
                      );
                    }
                    return (
                      <div className="resend-text-container">
                        <span className="text-info resend-text-container">
                          Resend code in
                        </span>{' '}
                        <span className="resend-otp-timer resend-text-container">
                          {seconds} seconds
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="text-center mt24">
            <SpinnerButton
              isLoading={submitting || loading}
              onClick={handleSubmit}
              disabled={invalid || !values.otp}
            >
              {isActivated && isPresent ? 'Update' : 'Set up'}
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default PhonepeSetupForm;
