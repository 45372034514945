import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppContext } from '../context/AppContext';
import { ClickPrevent, ExternalLink } from '../shared';
import ImageLoader from '../shared/ImageLoader';
import SpinnerButton from '../shared/SpinnerButton';
import { ArrowUpRight, PremiumTheme, SettingsIcon } from '../SvgIcon';
import { ENTERPRISE_PLUGIN_STATUS, RAZORPAY_APP_ID } from './constants';
import { RAZORPAY_KEY, usePayments } from '../context/PaymentsProvider';

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const Title = (props) => {
  const {
    name,
    logo,
    status,
    developer_name: developerName,
    developer_website: website,
    hasSettingsPage,
    id,
    isInstalled,
    togglePlugin,
  } = props;

  const history = useHistory();
  const { isEnterprise } = useAppContext();
  const { paymentKeys } = usePayments();
  const isRazorpayApp = id === RAZORPAY_APP_ID;

  const checkIsPluginInstalled = useCallback(() => {
    if (isRazorpayApp) {
      const selectedProvider = paymentKeys.find(
        (key) => key.provider === RAZORPAY_KEY
      );

      const activated =
        (selectedProvider?.is_active &&
          !selectedProvider?.is_dukaan_owned_account) ||
        false;

      return activated;
    }

    return isInstalled;
  }, [isRazorpayApp, isInstalled, paymentKeys]);

  const isPluginInstalled = checkIsPluginInstalled();

  return (
    <div className="plugin-title-header d-flex flex-column">
      <div className="plugin-title">
        <div className="plugin-logo mb24">
          <ImageLoader height={88} width={88} src={logo} alt="plugin logo" />
        </div>
        <div className="plugin-title-text mb24">
          {website ? (
            <div className="d-flex align-center">
              <div className="c-black-3 text-medium mr4">By</div>
              <div className="d-flex align-center">
                <ExternalLink
                  href={website}
                  target="_blank"
                  className="section-text-5 c-purple-1-i anchor-1"
                >
                  {developerName}
                </ExternalLink>
                <ArrowUpRight height={16} width={16} />
              </div>
            </div>
          ) : (
            <h6>{developerName}</h6>
          )}
          {/* <div className="plugin-rating">
            <Rating count={maxRatings} value={rating} edit={false} />
            <p>{ratingsCount} ratings</p>
          </div> */}
        </div>
      </div>

      <div className="d-flex flex-column align-center">
        {isPluginInstalled && hasSettingsPage && (
          <SpinnerButton
            className="btn btn-outline-4 w-100 text-medium plugin-detail-buttons justify-center d-flex"
            onClick={() => {
              if (isRazorpayApp) {
                history.push('/settings?section=payments');
                return;
              }
              history.push(`${id}/settings`, { ...history.location.state });
            }}
            type="button"
          >
            Settings
          </SpinnerButton>
        )}
        {isPluginInstalled ? (
          <span
            className="section-text-7 mt16 underline plugin-detail-buttons cur-p"
            onClick={togglePlugin}
          >
            Uninstall Plugin
          </span>
        ) : (
          <div className="w-100">
            {isEnterprise || status !== ENTERPRISE_PLUGIN_STATUS ? (
              <ClickPrevent>
                <SpinnerButton
                  persistLoader
                  onClick={togglePlugin}
                  label="Install Plugin"
                  className="btn-primary-4 w-100"
                />
              </ClickPrevent>
            ) : (
              <SpinnerButton
                onClick={togglePlugin}
                className="btn-primary-4 w-100"
              >
                <PremiumTheme height={14} width={14} className="mr6" />
                Install
              </SpinnerButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
