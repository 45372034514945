import React, { useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { AppBar, Toolbar } from '@material-ui/core';
import {
  MenuIcon,
  BackArrowIcon,
  BackArrowActiveIcon,
  HelpFilledIcon,
} from '../SvgIcon';

import { useAppContext } from '../context/AppContext';
import { noop } from '../utils';
import { TrackEvent } from '../utils/analytics';
import {
  EVENT_COUPON_HOW_TO_USE,
  EVENT_DASH_STORE_ON,
  EVENT_MANAGE_DINING_HOW_TO_USE,
  EVENT_MANAGE_EXTRA_CHARGES_HOW_TO_USE,
  EVENT_MANAGE_PAY_HOW_TO_USE,
  EVENT_MANAGE_STORE_QR_HOW_TO_USE,
  EVENT_MARK_BUSINESS_CARD_HOW_TO_USE,
  EVENT_MARK_PROMO_BANNER_HOW_TO_USE,
  EVENT_MARK_WA_STORY_HOW_TO_USE,
  EVENT_ORDER_HOW_TO_USE,
  EVENT_PROD_ADD_HOW_TO_USE,
  EVENT_PROD_BULK_HOW_TO_USE,
} from '../events';
import { HELP_LINKS, RAZORPAY_API_KEY } from '../constants';
import { STAFF_ROLE_OPTIONS } from '../Account/constants';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import useStoreStatusChange from '../hooks/useStoreStatusChange';
import { useSubscription } from '../context/SubscriptionProvider';
import SpinnerButton from '../shared/SpinnerButton';
import useQuery from '../hooks/useQuery';
import AppBarMenuItem from './AppBarMenuItem';
import {
  APP_BAR_MENU_ITEMS,
  DOWNLOAD_TYPE_KEY,
  NOTIFICATIONS_TYPE_KEY,
} from '../Home/constants';
import HelloBar from '../shared/InformationBar/HelloBar';
import OfferBar from '../Home/components/OfferBar';
import { useCountry } from '../context/CountryProvider';
import { useRazorpayCheckout } from '../Subscription/NewSubscription/PaymentButtons/RazorpayButton';
import { INVOICE_PAYMENT_URL } from '../ApiUrls';
import { getDukaanHelpCenterUrl } from '../shared/utils';
import { accountUrls } from '../Urls';
import { MOBILE_PLAN_LABEL } from '../Subscription/constants';
import { TRANSACTION_FEE_KEY_PLAN_STATS } from '../Manage/Payments/constants';
import useLocalStorage from '../hooks/useLocalStorage';
import useModal from '../hooks/useModal';
import RenewSubscriptionModal from './RenewSubscriptionModal';
import HelpMenu from './HelpMenu';

const HowToUseEvents = (url, business) => {
  if (url === HELP_LINKS.ADD_PRODUCT) {
    TrackEvent(EVENT_PROD_ADD_HOW_TO_USE, business);
  } else if (
    url === HELP_LINKS.ADD_BULK_BASIC ||
    url === HELP_LINKS.ADD_BULK_ADVANCE
  ) {
    TrackEvent(EVENT_PROD_BULK_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.BUSINESS_CARDS) {
    TrackEvent(EVENT_MARK_BUSINESS_CARD_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.STORE_BANNERS) {
    TrackEvent(EVENT_MARK_PROMO_BANNER_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.WHATSAPP_STORIES) {
    TrackEvent(EVENT_MARK_WA_STORY_HOW_TO_USE, business);
  } else if (
    url === HELP_LINKS.PAYMENTS_INDIA ||
    url === HELP_LINKS.PAYMENTS_INTERNATIONAL
  ) {
    TrackEvent(EVENT_MANAGE_PAY_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.STORE_QR) {
    TrackEvent(EVENT_MANAGE_STORE_QR_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.COUPONS) {
    TrackEvent(EVENT_COUPON_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.EXTRA_CHARGES) {
    TrackEvent(EVENT_MANAGE_EXTRA_CHARGES_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.DINING) {
    TrackEvent(EVENT_MANAGE_DINING_HOW_TO_USE, business);
  } else if (url === HELP_LINKS.ORDERS) {
    TrackEvent(EVENT_ORDER_HOW_TO_USE, business);
  }
};

export const HowItWorks = ({
  url,
  classes = '',
  customText = '',
  useAbsoluteUrl = false,
}) => {
  const { business, isAppSumoStore, isOwner } = useAppContext();
  if (isAppSumoStore || !isOwner) return null;
  return (
    <div
      className={cx('ml16', classes)}
      onClick={() => {
        if (business) {
          HowToUseEvents(url, business);
        }
      }}
    >
      <a
        href={useAbsoluteUrl ? url : getDukaanHelpCenterUrl(url)}
        target="_blank"
        rel="noreferrer"
        className="how-it-works d-flex align-center"
      >
        <HelpFilledIcon className="mr6" />
        {customText || 'How it works'}
      </a>
    </div>
  );
};

const OfflineBagde = ({ onClick = noop, isOnTrial }) => (
  <div
    className={cx('header-offline-container', {
      'header-offline-container-on-trial': isOnTrial,
    })}
  >
    <div className="header-offline-badge">
      <span>Your store is offline.</span>
      <span className="go-online" onClick={onClick}>
        Go online
      </span>
    </div>
  </div>
);

const Header = (props) => {
  const [hover, setHover] = useState(false);
  const {
    handleSidebarToggle,
    headerText,
    renderHeaderText,
    headerClass = 'align-center',
    headerParentClass = '',
    headerFixed,
    backLink = '',
    headerActions,
    howItWorksUrl,
    showStoreImage,
    showSearchBar,
    showMenu,
    showOfflineBadge,
    isAdvance,
    isStandard,
    editActions = {
      isLoading: false,
      isDisabled: false,
      saveCallback: noop,
      discardCallback: noop,
    },
    progressBar,
    isEditMode = false,
    fullWidth = false,
  } = props;
  const {
    business,
    roleName,
    fetchBusinessDetails,
    isAdmin,
    isManager,
    isManagerL1,
    isManagerL2,
    isStaff,
    isStaffL1,
    isStaffL2,
    isVendor,
    isAppSumoStore,
    isOwner,
    userDetails,
    isEnterprise,
    isPrimaryStore,
    festiveDiscountPercentage = 0,
  } = useAppContext();
  const { isInternational } = useCountry();

  const query = useQuery();
  const history = useHistory();
  const redirectUrl = query.get('forceRedirectUrl');
  const { isSubmitting, changeStoreStatus } = useStoreStatusChange();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { currencyCode } = useCountry();
  const {
    isOnTrial,
    hideSideBarState,
    dueInvoiceData,
    subscriptionData,
    isOldPlan,
    planStats,
    showSubscriptionExpiring,
    daysFromExpiry,
    renewalPlanDetails,
    isFreePlan,
  } = useSubscription();

  const { isPlanNDomainOpen, openPlanNDomainModal, closePlanNDomainModal } =
    useModal(false, { prefix: 'PlanNDomain' });

  const [downgradeBannerDismissed, setDowngradeBannerDismissed] =
    useLocalStorage('plan_downgrade_banner_dismissed', false);

  const { meta: { is_online: online } = {}, uuid: storeUuid } = business;
  const { name: storeName } = business;
  const { mobile } = userDetails;

  const showHelloBanner =
    subscriptionData?.can_upgrade_plan &&
    !isAppSumoStore &&
    isOwner &&
    !isInternational &&
    !isEnterprise;

  const showPlanDowngradedBanner =
    subscriptionData?.previous_plan_data &&
    subscriptionData?.name === MOBILE_PLAN_LABEL &&
    isPrimaryStore;

  const downgradedPlanServiceFee = planStats?.find(
    (it) => it.key === TRANSACTION_FEE_KEY_PLAN_STATS
  )?.value;

  const handleBack = () => {
    if (redirectUrl) {
      history.push(redirectUrl);
      return;
    }
    if (typeof backLink === 'function') {
      backLink();
    } else if (backLink !== '') {
      history.replace(backLink);
    } else {
      history.goBack();
    }
  };

  const successCallback = () => {
    enqueueSnackbar(`Store set to open`);
    fetchBusinessDetails();
    TrackEvent(EVENT_DASH_STORE_ON);
  };

  const onGoline = () => {
    if (!isSubmitting) {
      const params = { is_online: true, store_opens_in: 0 };
      changeStoreStatus(params, storeUuid, successCallback);
    }
  };

  const openCheckout = useRazorpayCheckout({
    url: INVOICE_PAYMENT_URL(dueInvoiceData?.uuid),
    payload: {},
    enqueueSnackbar,
  });

  const handleClickPay = () => {
    const config = {
      name: 'Dukaan Invoice',
      key: RAZORPAY_API_KEY,
      currency: currencyCode,
      description: `Payment for Dukaan Invoice`,
      prefill: {
        name: storeName,
        email: 'orders@mydukaan.io',
        contact: mobile,
      },
      handler: () => {
        window.location.reload();
      },
    };
    openCheckout(config);
  };

  return (
    <AppBar
      color="inherit"
      position="fixed"
      className={cx('main-header', {
        'full-width-header': hideSideBarState || fullWidth,
      })}
    >
      {showHelloBanner && !showSubscriptionExpiring ? <HelloBar /> : <></>}
      {!downgradeBannerDismissed && showPlanDowngradedBanner && (
        <OfferBar
          title={
            <p className="text-normal">
              Subscription expired: Limited store features. Reactivate for full
              access and premium benefits!
            </p>
          }
          btnLabel="Subscribe now"
          onClick={() => {
            history.push(accountUrls.subscriptionPurchasePath);
          }}
          onClose={() => {
            setDowngradeBannerDismissed(true);
          }}
        />
      )}
      {showSubscriptionExpiring && (
        <OfferBar
          danger
          title={
            <p className="text-normal">
              Your subscription will expire in <b>{daysFromExpiry}</b> day
              {daysFromExpiry > 1 ? 's' : ''}. After that, access to full
              features will be limited.
            </p>
          }
          btnLabel="Renew now"
          onClick={() => {
            if (isOldPlan) {
              history.push(accountUrls.subscriptionPurchasePath);
            } else {
              openPlanNDomainModal();
            }
          }}
        />
      )}

      {showOfflineBadge && !online && (
        <OfflineBagde onClick={onGoline} isOnTrial={isOnTrial} />
      )}
      <Toolbar className="header-toolbar" style={{ position: 'relative' }}>
        <div
          className={cx('header-title-wrap', headerParentClass, {
            fixed: headerFixed,
            advance: isAdvance,
            standard: isStandard,
          })}
        >
          <div className="header-menu-icon" onClick={handleSidebarToggle}>
            <MenuIcon />
          </div>

          {backLink !== null && !isEditMode ? (
            <>
              <div
                role="button"
                tabIndex="-1"
                className="d-flex align-center full-w navbar-header"
              >
                <div
                  style={{
                    minHeight: 44,
                    minWidth: 44,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {hover ? (
                    <BackArrowActiveIcon
                      className="header-back-link"
                      onClick={handleBack}
                      onMouseLeave={() => setHover(false)}
                    />
                  ) : (
                    <BackArrowIcon
                      className="header-back-link"
                      onClick={handleBack}
                      onMouseEnter={() => setHover(true)}
                    />
                  )}
                </div>
                <div className="cur-p" onClick={handleBack}>
                  {renderHeaderText ? (
                    renderHeaderText()
                  ) : (
                    <h2 className="section-text-3 c-black-1">
                      {isEditMode ? 'Unsaved changes' : headerText}
                    </h2>
                  )}
                </div>

                {howItWorksUrl && !isEditMode && !isVendor && (
                  <HowItWorks url={howItWorksUrl} />
                )}
              </div>
            </>
          ) : (
            <div>
              <h2 className={`section-text-3 navbar-header  ${headerClass}`}>
                {showStoreImage && (
                  <div className="d-flex align-center">
                    <div className="store-name-container">
                      <div className="header-label">
                        <div className="d-flex align-center">
                          <span>{headerText}</span>
                        </div>
                        {(isAdmin ||
                          isManager ||
                          isManagerL1 ||
                          isManagerL2 ||
                          isStaff ||
                          isStaffL1 ||
                          isStaffL2) && (
                          <p className="text-10 c-purple-1">
                            {
                              STAFF_ROLE_OPTIONS.find(
                                (option) => option.value === roleName
                              )?.label
                            }{' '}
                            account
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!showStoreImage &&
                  (isEditMode ? 'Unsaved changes' : headerText)}
                {howItWorksUrl && !isEditMode && !isVendor && (
                  <HowItWorks url={howItWorksUrl} business={business} />
                )}
              </h2>
            </div>
          )}
          {headerActions}
        </div>
        {isEditMode && (
          <div className="d-flex align-center">
            <button
              className="btn-outline-4 mr10"
              type="button"
              onClick={() => editActions.discardCallback()}
            >
              Discard
            </button>
            <SpinnerButton
              disabled={editActions.isDisabled}
              isLoading={editActions.isLoading}
              onClick={() => {
                editActions.saveCallback();
              }}
            >
              Save
            </SpinnerButton>
          </div>
        )}

        {/* {!isInternational && isFreePlan && window.location.pathname === '/' && (
          <>
            <div
              className="live-webinar-link"
              onClick={() => {
                window.open(DUKAAN_COACH_URL, '_blank');
              }}
            >
              <span className="live-pulse mr8" />
              <p className="min-w-fit text-medium">Join live webinar</p>
            </div>
            <div className="vertical-divider whhite-2 ml20 mr12" />
          </>
        )} */}
        {!isAppSumoStore && showMenu && !isEditMode && !isVendor && (
          <>
            <HelpMenu />
            <div className="vertical-divider whhite-2 ml12 mr20" />
          </>
        )}

        {showMenu && !isEditMode && (
          <div className="app-bar-menu-items">
            {APP_BAR_MENU_ITEMS.map((item, index) => {
              if (
                (item.typeKey === DOWNLOAD_TYPE_KEY ||
                  item.typeKey === NOTIFICATIONS_TYPE_KEY) &&
                !(isAdmin || isOwner || isManager)
              )
                return null;
              return <AppBarMenuItem {...item} key={index} />;
            })}
          </div>
        )}
      </Toolbar>
      {progressBar > 0 && (
        <div
          className="progress-bar"
          style={{
            height: '4px',
            width: `${progressBar}%`,
            backgroundColor: '#FAB73B',
          }}
        />
      )}

      {isPlanNDomainOpen && (
        <RenewSubscriptionModal
          open={isPlanNDomainOpen}
          closeModal={closePlanNDomainModal}
          planDetails={renewalPlanDetails}
          showExploreOtherPlans
        />
      )}
    </AppBar>
  );
};

Header.defaultProps = {
  headerText: '',
  backLink: null,
  showMenu: false,
  showSearchBar: true,
};

export default Header;
